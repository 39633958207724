import React, { useState } from 'react';
import { LeftMenu, MainHeader } from '../../components';
import { Tab, Tabs, Container } from '@mui/material';
import ToggleSwitch from './ToggleSwitch';
import StudentSyncToggleSetting from './StudentSyncToggleSetting';
import ParentSyncSetting from './ParentSyncSetting';
import ClassesSyncSetting from './ClassesSyncSetting';
import AttendanceSyncSetting from './AttendanceSyncSetting';
import AchievementSyncSetting from './AchievementSyncSetting';
import BehaviourSyncSetting from './BehaviourSyncSetting';
import SubjectSyncSetting from './SubjectSyncSetting';
import { SyncSettings } from './PeopleMgtStyle';
import ResponsiveAppBar from '../../components/common/ResponsiveAppBar';

const SyncSetting = () => {
  const [tabValue, setTabValue] = useState(0);

  // Function to handle tab change
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <>
      {/* <div className='left-container'>
        <LeftMenu />
      </div> */}

      <ResponsiveAppBar />
      <Container maxWidth="lg">
        {/* <MainHeader /> */}
        {/* Tabs */}
        <h3>Sync Setting</h3>
        <SyncSettings>
          <div className='sync-tabs'>
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab label='STAFF' />
              <Tab label='STUDENT' />
              <Tab label='PARENTS' />
              <Tab label='CLASSES' />
              <Tab label='ATTENDANCE' />
              <Tab label='BEHAVIOUR' />
              <Tab label='ACHIEVEMENT' />
              <Tab label='SUBJECTS' />
            </Tabs>
          </div>

          <div className='content-container'>
            {tabValue === 0 && (
              <div className='tabs-content'>
                <p>Choose which data to be Sync from your MIS below:</p>
                <div>
                  <ToggleSwitch />
                </div>
              </div>
            )}

            {tabValue === 1 && (
              <div className='tabs-content'>
                <p>
                  As Minimum Name, Date of Birth, Email, MIS Id, NC Year Group
                  and form will be Synced. You can choose the addition data to
                  sync below:
                </p>
                <div>
                  <StudentSyncToggleSetting />
                </div>
              </div>
            )}

            {tabValue === 2 && (
              <div className='tabs-content'>
                <p>
                  As Minimum Name, Date of Birth, Email, MIS Id will be Synced.
                  You can choose the addition data to sync below:
                </p>
                <div>
                  <ParentSyncSetting />
                </div>
              </div>
            )}

            {tabValue === 3 && (
              <div className='tabs-content'>
                <p>
                  As Minimum Expect below all field will be Synced. You can
                  choose the addition data to sync below:
                </p>
                <div>
                  <ClassesSyncSetting />
                </div>
              </div>
            )}

            {tabValue === 4 && (
              <div className='tabs-content'>
                <p>
                  As Minimum Expect below all field will be Synced. You can
                  choose the addition data to sync below:
                </p>
                <div>
                  <AttendanceSyncSetting />
                </div>
              </div>
            )}

            {tabValue === 5 && (
              <div className='tabs-content'>
                <p>
                  As Minimum Expect below all field will be Synced. You can
                  choose the addition data to sync below:
                </p>
                <div>
                  <BehaviourSyncSetting />
                </div>
              </div>
            )}

            {tabValue === 6 && (
              <div className='tabs-content'>
                <p>
                  As Minimum Expect below all field will be Synced. You can
                  choose the addition data to sync below:
                </p>
                <div>
                  <AchievementSyncSetting />
                </div>
              </div>
            )}

            {tabValue === 7 && (
              <div className='tabs-content'>
                <p>
                  As Minimum Expect below all field will be Synced. You can
                  choose the addition data to sync below:
                </p>
                <div>
                  <SubjectSyncSetting />
                </div>
              </div>
            )}
          </div>
        </SyncSettings>
      </Container>
    </>
  );
};

export default SyncSetting;
