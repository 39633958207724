import React, { useState } from 'react';
import axios from 'axios';
import { Grid, Button, Typography, Switch, Stack } from '@mui/material';
import { storageService } from '../../services';
import { PUPIL_API_URL } from './PupilConst';
import { toast } from 'react-toastify';
import { PeopleMgtSwitch } from './PeopleMgtStyle';

function ParentSyncSetting() {
  const [toggles, setToggles] = useState({
    DateofBirth: false,
    ParentResponsibility: false,
  });

  const handleToggle = (name) => {
    setToggles((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const postData = async () => {
    //get school id
    var schoolId = storageService.getItem('schoolId');
    try {
      const data = { SchoolId: schoolId, ...toggles };

      console.log('toggles', data);
      const response = await axios.post(
        `${PUPIL_API_URL}/Student/SyncParentSettingBySchoolId`,
        data
      );

      if (response.data === 'Record Updated') {
        toast.success('Parent sync setting has been updated successfully.');
      } else {
        toast.error('Something went wrong.');
      }
    } catch (error) {
      toast.error('Something went wrong.', error);
    }
  };
  return (
    <Grid container spacing={2.5} mt={2}>
      {Object.keys(toggles).map((toggleName, index) => (
        <Grid item xs={12} sm={4} md={3} lg={3} key={index}>
          <Stack direction='row' spacing={1} alignItems='center'>
            <PeopleMgtSwitch
              checked={toggles[toggleName]}
              onChange={() => handleToggle(toggleName)}
            />
            <Typography variant='body2'>{toggleName}</Typography>
          </Stack>
        </Grid>
      ))}
      <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
        <Stack direction='row' justifyContent='flex-end' spacing={1}>
          <Button
            variant='outlined'
            className='save-btn'
            onClick={postData}
            disableRipple
          >
            Save
          </Button>
          <Button
            variant='contained'
            className='sync-btn'
            onClick={postData}
            disableRipple
            disableElevation
          >
            Sync Now
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default ParentSyncSetting;
