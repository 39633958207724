import React from 'react';
import { Roles } from '../utils/Constants';
import {
  RequestAssistance,
  LearnMore,
  OtherSuggestion,
  Login,
  ActionPlan,
  ForgotPasswordForm,
  ResetPasswordForm,
  Account,
  StaffResults,
  WelbeeVoice,
  StaffManagement,
  RegisterDirector,
  MatSchools,
  MatStaff,
  FilterLibrary,
} from '../views';

import ThankYouMessage from '../components/common/ThankYouMessage';
import NotFound from '../views/PageNotFound/NotFound';
import StudentList from '../views/pupil/StudentList';
import SyncSetting from '../views/pupil/SyncSetting';
import AccessManagement from '../views/accessManagement/AccessManagement';

const routesObj = {
  Root: {
    path: '/',
    component: <Login />,
    roles: [],
    fallback: null,
  },

  RequestAssistance: {
    path: '/request-assistance',
    component: <RequestAssistance />,
    roles: [Roles.SuperAdmin, Roles.HeadTeacher, Roles.GroupDirector],
    fallback: null,
  },

  OtherSuggestion: {
    path: '/other-suggestion',
    component: <OtherSuggestion />,
    roles: [Roles.SuperAdmin, Roles.HeadTeacher],
    fallback: null,
  },
  Login: {
    path: '/login',
    component: <Login />,
    roles: [],
    fallback: null,
  },
  ThankYouMessage: {
    path: '/thankyou-message',
    component: <ThankYouMessage />,
    roles: [],
    fallback: null,
  },

  ActionPlan: {
    path: '/action-plan',
    component: <ActionPlan />,
    roles: [Roles.SuperAdmin, Roles.HeadTeacher, Roles.GroupDirector],
    fallback: null,
  },

  ForgotPassword: {
    path: '/forgot-password',
    component: <ForgotPasswordForm />,
    roles: [],
    fallback: null,
  },
  ResetPassword: {
    path: '/reset-password',
    component: <ResetPasswordForm />,
    roles: [],
    fallback: null,
  },
  Account: {
    path: '/account',
    component: <Account />,
    roles: [Roles.HeadTeacher, Roles.GroupDirector],
    fallback: null,
  },
  StaffResults: {
    path: '/staff-results',
    component: <StaffResults />,
    roles: [Roles.HeadTeacher, Roles.SuperAdmin, Roles.GroupDirector],
    fallback: null,
  },
  VoiceUserGuide: {
    path: '/voice-instructions',
    roles: [Roles.HeadTeacher, Roles.SuperAdmin, Roles.GroupDirector],
    fallback: null,
  },
  StaffManagement: {
    path: '/staff-management',
    component: <StaffManagement />,
    roles: [Roles.HeadTeacher, Roles.SuperAdmin, Roles.GroupDirector],
    fallback: null,
  },
  AccessManagement: {
    path: '/access-management',
    component: <AccessManagement />,
    roles: [Roles.HeadTeacher, Roles.SuperAdmin, Roles.GroupDirector],
    fallback: null,
  },
  RegisterDirector: {
    path: '/register-director',
    component: <RegisterDirector />,
    fallback: null,
  },
  MatSchools: {
    path: '/mat-schools',
    component: <MatSchools />,
    roles: [Roles.SuperAdmin],
    fallback: null,
  },

  MatStaffManagement: {
    path: '/mat-staff',
    component: <MatStaff />,
    roles: [Roles.HeadTeacher, Roles.SuperAdmin, Roles.GroupDirector],
    fallback: null,
  },

  StudentList: {
    path: '/student-list',
    component: <StudentList />,
    roles: [Roles.SuperAdmin, Roles.HeadTeacher, Roles.GroupDirector],
    fallback: null,
  },
  StudentSyncSetting: {
    path: '/student-sync-setting',
    component: <SyncSetting />,
    roles: [Roles.SuperAdmin, Roles.HeadTeacher, Roles.GroupDirector],
    fallback: null,
  },

  FilterLibrary: {
    path: '/filter-library',
    component: <FilterLibrary />,
    roles: [Roles.SuperAdmin],
    fallback: null,
  },
  NotFound: {
    component: <NotFound />,
    fallback: null,
  },
};
export default routesObj;
