import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import KeyIcon from '@mui/icons-material/Key';
import LockIcon from '@mui/icons-material/Lock';
import UploadIcon from '@mui/icons-material/Upload';
import VpnKeyOffIcon from '@mui/icons-material/VpnKeyOff';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import { GridActionsCellItem } from '@mui/x-data-grid';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { LeftMenu, MainHeader } from '../../components';
import AccessLevel from '../../components/common/AccessLevel';
import { ActionPlanMainWrapper } from '../../components/common/ActionPlanStyle';
import ConfirmPopupForm from '../../components/common/ConfirmPopupForm';
import { HeaderDataGrid } from '../../resources/styling/appStyle';
import {
  schoolService,
  schoolStaffService,
  storageService,
} from '../../services';
import { navigationActions } from '../../store/headerStore';
import { loaderAction } from '../../store/loaderStore';
import { WebStorageNames } from '../../utils';
import {
  getSelectedRow,
  getStaffJobRole,
  getStaffJobTitle,
} from '../../utils/CommonFunctions';
import { AccessPermissions, Roles } from '../../utils/Constants';
import AddStaffMember from '../staff/AddStaffMember';
import EditStaffMember from '../staff/EditStaffMember';
import GrantAccess from '../staff/GrantAccess';
import RevokeAccess from '../staff/RevokeAccess';
import StaffExcel from './StaffExcel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ResponsiveAppBar from '../../components/common/ResponsiveAppBar';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
}));

const SchoolStaff = (props) => {
  const { loaderActions } = props;
  const [isShowExcelUpload, setIsShowExcelUpload] = useState(false);
  const [schoolId, setSchoolId] = useState('');
  const [loggedInRole, setLoggedInRole] = useState('');
  const [staffId, setStaffId] = useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    id: false,
    accessLevel: false,
  });
  const [isSyncedFromWonde, setIsSyncedFromWonde] = useState(false);

  const [isShowAddStaff, setIsShowAddStaff] = useState(false);
  const [isShowEditStaff, setIsShowEditStaff] = useState(false);
  const [isShowGrantAccess, setIsShowGrantAccess] = useState(false);
  const [isShowRevokeAccess, setIsShowRevokeAccess] = useState(false);
  const [isRemovePopupOpen, setIsRemovePopupOpen] = useState(false);
  const [jobRole, setJobRole] = useState('');
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
    queryOptions: { field: '', sort: '' },
    value: null,
    filterOptions: { field: '', value: 0 },
  });
  const [isShowInfoMsg, setIsShowInfoMsg] = useState(false);
  const [isUnVerifiedJobTitles, setIsUnVerifiedJobTitles] = useState(false);
  useEffect(() => {
    const id = storageService.getItem(WebStorageNames.SchoolId);
    const loggedinrole = storageService.getItem(WebStorageNames.Role);
    setSchoolId(id);
    setLoggedInRole(loggedinrole);
    scrollToTop();
  }, []);

  useEffect(() => {
    getStaff();
  }, [
    pageState.page,
    pageState.pageSize,
    pageState.queryOptions,
    pageState.value,
    pageState.filterOptions,
  ]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleInfoMsgClose = () => {
    setIsShowInfoMsg(false);
  };

  const handleExcelUpload = () => {
    setIsShowInfoMsg(true);
  };

  const handleJobRoleChange = (e) => {
    setJobRole(e.target.value);
    setPageState((old) => ({
      ...old,
      isLoading: true,
      total: 0,
      page: 1,
      pageSize: old.pageSize,
      filterOptions: {
        field: 'Job Role',
        value: e.target.value,
      },
    }));
  };

  const handleSortModelChange = React.useCallback((sortModel) => {
    if (sortModel?.length > 0) {
      setPageState((old) => ({
        ...old,
        isLoading: true,
        total: 0,
        page: 1,
        pageSize: old.pageSize,
        queryOptions: {
          field: sortModel[0].field,
          sort: sortModel[0].sort,
        },
      }));
    }
  }, []);

  const handleSearch = (value) => {
    if (value?.length > 2 || value === '') {
      setPageState((old) => ({
        ...old,
        isLoading: true,
        total: 0,
        page: 1,
        pageSize: old.pageSize,
        value: value,
      }));
    }
  };

  const handleDelete = (id) => {
    schoolService
      .deleteSchoolStaff(id)
      .then((response) => {
        const { success } = response;
        if (success) {
          toast.success('Record deleted successfully.');
          setIsRemovePopupOpen(false);
          getStaff();
        }
      })
      .catch((error) => {});
  };

  const getStaff = () => {
    const id = storageService.getItem(WebStorageNames.SchoolId);
    debugger;
    //Set PageState
    if (pageState !== undefined) {
      setPageState((old) => ({
        ...old,
        isLoading: true,
        data: old.data,
        total: old.total,
        queryOptions: old.queryOptions,
        filterOptions: old.filterOptions,
        value: old.value,
      }));
    }

    let filter = null;
    if (pageState?.filterOptions?.field !== '') {
      filter = [
        {
          Field: pageState.filterOptions.field,
          Value: pageState.filterOptions.value,
        },
      ];
    }

    let order = null;
    if (pageState?.queryOptions?.field !== '') {
      order = [
        {
          Field: pageState.queryOptions.field,
          Sort: pageState.queryOptions.sort,
        },
      ];
    }
    //Get Staff Of School
    const model = {
      Start: (pageState.page - 1) * pageState.pageSize,
      Length: pageState.pageSize,
      Order: order,
      Filter: filter,
      Search: {
        SchoolId: id,
        Value: pageState.value,
      },
      Columns: [],
    };

    schoolStaffService
      .getSchoolStaff(model)
      .then((response) => {
        const { data, success } = response;
        if (success) {
          const { IsJobRoleVerified } = data;
          if (!IsJobRoleVerified) setIsUnVerifiedJobTitles(true);
          const dataRow = [...data.Data].map((item, index) => {
            if (item.IsSyncedFromWonde) {
              setIsSyncedFromWonde(true);
            }
            return {
              id: item.Id,
              firstName: item.FirstName,
              lastName: item.LastName,
              email: item.Email,
              accessType: item.AccessType,
              jobRole: item.JobRole,
              accessLevel: item.IsGranted ? item.AccessLevel : null,
              coreFilters: item.CoreFilters,
              filters: item.FilterValues,
            };
          });
          setPageState((old) => ({
            ...old,
            isLoading: false,
            data: dataRow,
            total: data.RecordsFiltered,
          }));
          // setRows(dataRow);
        } else {
          setPageState((old) => ({
            ...old,
            isLoading: false,
          }));
        }
      })
      .catch((error) => {
        setPageState((old) => ({
          ...old,
          isLoading: false,
          data: old.data,
          total: old.total,
        }));
        loaderActions.dataLoading(false);
      });
  };

  const columns = [
    { field: 'id', headerName: 'Id' },
    {
      field: 'firstName',
      headerName: 'First Name',
      flex: 0.3,
    },
    {
      field: 'lastName',
      headerName: 'Surname',
      flex: 0.3,
    },
    { field: 'email', headerName: 'Email', flex: 0.5 },
    {
      field: 'jobTitle',
      headerName: 'Job Title',
      sortable: false,
      flex: 0.4,
      valueGetter: getStaffJobTitle,
    },
    {
      field: 'jobRole',
      headerName: 'Job Role',
      sortable: false,
      flex: 0.4,
      valueGetter: getStaffJobRole,
      cellClassName: (params) => {
        return clsx('filter-lib', {
          negative: params.value == null || params.value === '',
        });
      },
    },
    {
      field: 'accessLevel',
      headerName: 'Access',
      sortable: false,
      flex: 0.4,
    },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      hideable: false,
      renderCell: (params) => {
        const handleUpdate = (e) => {
          let row = getSelectedRow(params);
          setStaffId(JSON.stringify(row.id, null, 4));
          setIsShowEditStaff(true);
        };

        const onDeleteClick = (e) => {
          e.stopPropagation();
          let row = getSelectedRow(params);
          let id = JSON.stringify(row.id, null, 4);
          setStaffId(id);
          setIsRemovePopupOpen(true);
        };

        const handleAccess = (e) => {
          e.stopPropagation();
          let row = getSelectedRow(params);
          setStaffId(JSON.stringify(row.id, null, 4));
          row.accessLevel !== null
            ? setIsShowRevokeAccess(true)
            : setIsShowGrantAccess(true);
        };
        return (
          <div>
            <AccessLevel
              permissions={[
                AccessPermissions.Full,
                AccessPermissions.HeadTeacher,
              ]}
            >
              {params.row.accessLevel !== null ? (
                params.row.accessType === 1 ? (
                  <GridActionsCellItem
                    icon={<KeyIcon onClick={handleAccess} />}
                    label='LockOpen'
                  />
                ) : (
                  <GridActionsCellItem
                    icon={<VpnKeyOffIcon />}
                    label='Stop'
                    disabled
                  />
                )
              ) : (
                <GridActionsCellItem
                  icon={<LockIcon onClick={handleAccess} />}
                  label='Lock'
                />
              )}
            </AccessLevel>
            <AccessLevel
              permissions={[
                AccessPermissions.Full,
                AccessPermissions.HeadTeacher,
                AccessPermissions.Admin,
              ]}
            >
              <GridActionsCellItem
                icon={<EditIcon onClick={handleUpdate} />}
                label='Edit'
              />
              <GridActionsCellItem
                icon={<DeleteIcon onClick={onDeleteClick} />}
                label='Delete'
              />
            </AccessLevel>
          </div>
        );
      },
    },
  ];

  const handleClose = () => {
    setIsShowExcelUpload(false);
    setIsShowAddStaff(false);
    setIsShowEditStaff(false);
    setIsShowGrantAccess(false);
    setIsShowRevokeAccess(false);
  };

  const exportExcelTemplate = () => {
    schoolStaffService
      .exportStaffExcelTemplate(schoolId)
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'School Staff Template.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {});
  };

  const exportExcelStaff = () => {
    schoolStaffService
      .exportStaffExcel(schoolId)
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'School Staff Data.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {});
  };

  const handleCheckboxChange = (e) => {
    console.log(e.target.checked, 'isChecked');
    const isChecked = e.target.checked;
    if (isChecked) {
      const data = pageState.data.filter((x) => !x.coreFilters[0].JobTitle);
      console.log('data', pageState.data);
      setPageState((prevState) => ({
        ...prevState,
        data: data,
      }));
    } else {
      getStaff();
    }
  };

  return (
    <ActionPlanMainWrapper>
      {/* <div className='left-container'>
        <LeftMenu />
      </div> */}
      <Container maxWidth='xl'>
        {/* <MainHeader /> */}
        {/* <ResponsiveAppBar /> */}

        <Box
          spacing={2}
          sx={{
            width: '100%',
            '& .filter-lib.negative': {
              backgroundColor: 'rgba(0,0,0, 0.1)',
            },

            flexGrow: 1,
          }}
        >
          <Grid container>
            <Grid Item lg={4} md={4} xs={12}>
              <Stack direction='row' justifyContent={'flex-start'}>
                <h2 style={{ marginTop: '0' }}>Staff Management</h2>
              </Stack>
            </Grid>
            <Grid item lg={8} md={8} xs={12}>
              <AccessLevel
                permissions={[
                  AccessPermissions.Full,
                  AccessPermissions.HeadTeacher,
                  AccessPermissions.Admin,
                ]}
              >
                <Stack direction='row' justifyContent={'flex-end'} spacing={2}>
                  <Button
                    style={{
                      backgroundColor: '#007A7A',
                    }}
                    startIcon={<UploadIcon />}
                    variant='contained'
                    disableElevation
                    component='span'
                    onClick={() => {
                      setIsShowExcelUpload(true);
                    }}
                    disabled={
                      loggedInRole === Roles.GroupDirector ? 'disabled' : null
                    }
                  >
                    Upload Excel
                  </Button>

                  {pageState.total > 0 ? (
                    <Button
                      style={{
                        backgroundColor: '#E84F6B',
                      }}
                      variant='contained'
                      disableElevation
                      onClick={() => {
                        exportExcelStaff();
                      }}
                    >
                      Download Current Data
                    </Button>
                  ) : null}

                  {pageState.total < 1 ? (
                    <Button
                      style={{
                        backgroundColor: '#45338C',
                      }}
                      variant='contained'
                      disableElevation
                      onClick={() => {
                        exportExcelTemplate();
                      }}
                    >
                      Download Template
                    </Button>
                  ) : null}
                </Stack>
              </AccessLevel>
            </Grid>
            {isUnVerifiedJobTitles && isShowInfoMsg ? (
              <Grid>
                <Alert onClose={handleInfoMsgClose} severity='info'>
                  One or more job titles have not been recognised within our
                  library. A member of our support team will contact you and
                  there is no action for you to take.
                </Alert>
              </Grid>
            ) : null}

            <Grid lg={12} md={12} xs={12} my='20px'>
              <Item>
                <Grid container>
                  <Grid item lg={5} md={5} xs={12} textAlign='left'>
                    <h2 style={{ margin: '0px' }}>Staff Members</h2>
                  </Grid>
                  <Grid item lg={7} md={7} xs={12}>
                    <Stack
                      direction='row'
                      alignItems='flex-end'
                      justifyContent='flex-end'
                      spacing={1}
                    >
                      {isSyncedFromWonde && (
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{
                                  '& .MuiSvgIcon-root': {
                                    fontSize: 20,
                                  },
                                }}
                                onChange={(e) => handleCheckboxChange(e)}
                              />
                            }
                            label='Missing Job Title'
                          />
                        </FormGroup>
                      )}

                      <TextField
                        className='staff-search-txtfield'
                        size='small'
                        name='search'
                        id='search'
                        label='Search'
                        onChange={(e) => {
                          handleSearch(e.target.value);
                        }}
                      />
                      <FormControl
                        sx={{ minWidth: 200 }}
                        size='small'
                        className='staff-search-dd'
                      >
                        <InputLabel id='job-roleselect-label'>
                          Job Role
                        </InputLabel>
                        <Select
                          labelId='job-role-select-label'
                          id='jobRole'
                          value={jobRole}
                          label='Job Role'
                          onChange={handleJobRoleChange}
                        >
                          <MenuItem value={-1}>All</MenuItem>
                          <MenuItem value={0}>None</MenuItem>
                          <MenuItem value={4}>
                            Teaching Staff (Classroom)
                          </MenuItem>
                          <MenuItem value={8}>
                            Teaching Staff (Non-Classroom)
                          </MenuItem>
                          <MenuItem value={16}>
                            Support Staff (Classroom)
                          </MenuItem>
                          <MenuItem value={32}>
                            Support Staff (Non-Classroom)
                          </MenuItem>
                          <MenuItem value={64}>Middle Leader</MenuItem>
                          <MenuItem value={128}>Senior Leader</MenuItem>
                        </Select>
                      </FormControl>

                      <AccessLevel
                        permissions={[
                          AccessPermissions.Full,
                          AccessPermissions.HeadTeacher,
                          AccessPermissions.Admin,
                        ]}
                      >
                        <Tooltip title='Add New Staff Member' followCursor>
                          <IconButton
                            style={
                              loggedInRole === Roles.GroupDirector
                                ? {
                                    backgroundColor: '#FF5939',
                                    color: '#ffffff',
                                    opacity: 0.3,
                                  }
                                : {
                                    backgroundColor: '#FF5939',
                                    color: '#ffffff',
                                  }
                            }
                            variant='contained'
                            aria-label='Add'
                            size='small'
                            onClick={() => {
                              setIsShowAddStaff(true);
                            }}
                            disabled={
                              loggedInRole === Roles.GroupDirector
                                ? 'disabled'
                                : null
                            }
                          >
                            <AddIcon />
                          </IconButton>
                        </Tooltip>
                      </AccessLevel>
                    </Stack>
                  </Grid>

                  <Grid item lg={12} md={12} xs={12} mt='20px'>
                    <div style={{ height: 511, width: '100%' }}>
                      <HeaderDataGrid
                        rows={pageState.data}
                        rowCount={pageState.total}
                        loading={pageState.isLoading}
                        sortingMode='server'
                        onSortModelChange={handleSortModelChange}
                        rowsPerPageOptions={[10, 30, 50, 70, 100]}
                        pagination
                        page={pageState.page - 1}
                        pageSize={pageState.pageSize}
                        paginationMode='server'
                        onPageChange={(newPage) => {
                          setPageState((old) => ({
                            ...old,
                            page: newPage + 1,
                          }));
                        }}
                        onPageSizeChange={(newPageSize) =>
                          setPageState((old) => ({
                            ...old,
                            pageSize: newPageSize,
                          }))
                        }
                        rowHeight={40}
                        columns={columns}
                        disableColumnMenu
                        disableColumnFilter={true}
                        disableColumnSelector={true}
                        disableDensitySelector={true}
                        disableExportButton={true}
                        disableSelectionOnClick={true}
                        getRowClassName={(params) =>
                          params.indexRelativeToCurrentPage % 2 === 0
                            ? 'even'
                            : 'odd'
                        }
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                          setColumnVisibilityModel(newModel)
                        }
                        components={{
                          NoRowsOverlay: () => (
                            <Stack
                              height='100%'
                              alignItems='center'
                              justifyContent='center'
                            >
                              No record
                            </Stack>
                          ),
                          NoResultsOverlay: () => (
                            <Stack
                              height='100%'
                              alignItems='center'
                              justifyContent='center'
                            >
                              No record
                            </Stack>
                          ),
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Item>
            </Grid>
          </Grid>

          {isShowExcelUpload ? (
            <StaffExcel
              handleClose={handleClose}
              exportExcelTemplate={exportExcelTemplate}
              exportExcelStaff={exportExcelStaff}
              getStaff={getStaff}
              handleExcelUpload={handleExcelUpload}
            />
          ) : (
            <div></div>
          )}

          {isShowAddStaff ? (
            <AddStaffMember
              handleClose={handleClose}
              getStaff={getStaff}
              matSchools={null}
              isMat={false}
              schoolId={schoolId}
            />
          ) : (
            <div></div>
          )}

          {isShowEditStaff ? (
            <EditStaffMember
              handleClose={handleClose}
              staffId={staffId}
              matSchools={null}
              isMat={false}
              schoolId={schoolId}
              getStaff={getStaff}
            />
          ) : (
            <div></div>
          )}

          {isShowGrantAccess ? (
            <GrantAccess
              handleClose={handleClose}
              staffId={staffId}
              matSchools={null}
              isMat={false}
              getStaff={getStaff}
            />
          ) : (
            <div></div>
          )}

          {isShowRevokeAccess ? (
            <RevokeAccess
              handleClose={handleClose}
              staffId={staffId}
              matSchools={null}
              isMat={false}
              getStaff={getStaff}
            />
          ) : (
            <div></div>
          )}

          {isRemovePopupOpen ? (
            <ConfirmPopupForm
              isOpen={isRemovePopupOpen}
              onClose={() => {
                setIsRemovePopupOpen(false);
                setStaffId('');
              }}
              onOk={() => {
                handleDelete(staffId);
              }}
              successPopupMessage='Are you sure to delete this record?'
            />
          ) : (
            <div></div>
          )}
        </Box>
      </Container>
    </ActionPlanMainWrapper>
  );
};

const mapDispatchToProps = (dispatch) => ({
  loaderActions: bindActionCreators(loaderAction, dispatch),
  navigationActions: bindActionCreators(navigationActions, dispatch),
});

export default connect(null, mapDispatchToProps)(SchoolStaff);
