import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import { isEmpty } from 'lodash';
import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Sidebar from 'react-sidebar';
import { bindActionCreators } from 'redux';
import RequestAssistanceButton from '../../components/common/RequestAssistanceButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  iconAccount,
  iconActionPlan,
  iconAnalytics,
  iconComments,
  iconConversation,
  iconDashboard,
  iconHeatmap,
  iconPresentation,
  iconQuestionBank,
  iconReport,
  iconStaffManagement,
  iconUserGuide,
  welbeeDashboardLogo,
} from '../../resources/images/index';
import { routesObj } from '../../routes';
import {
  schoolSubscriptionService,
  staffResultServices,
  storageService,
  accountService,
  schoolService,
  surveyReportService,
} from '../../services';
import { navigationActions } from '../../store/headerStore';
import {
  HeadTeacherAccess,
  MenuItems,
  QueryStringTokens,
  toggleOption,
  WebStorageNames,
} from '../../utils';
import {
  amplitudeLog,
  DateFormatWithMonth,
  freePeriodNoOfDaysLeft,
  getQueryStringVal,
  isUpgradeVoideDisplay,
} from '../../utils/CommonFunctions';
import {
  Roles,
  UserGuideSurveyLink,
  UserGuideVoiceLink,
  WelbeeTypes,
  AccessPermissions,
} from '../../utils/Constants';

import { IsActionAllowed, RestrictedActions } from '../../utils';
import IdleTimerContainer from './IdleTimerContainer';
import AccessLevel from '../../components/common/AccessLevel';

const styles = {
  contentHeaderMenuLink: {
    textDecoration: 'none',
    color: '#ffffff',
    background: '#FF5939',
    width: 38,
    height: 38,
    display: 'inline-block',
    textAlign: 'center',
    lineHeight: '53px',
    borderRadius: '8px',
  },
};

const mql = window.matchMedia('(min-width: 1000px)');

class LeftMenu extends Component {
  constructor(props) {
    super();

    this.state = {
      navActiveMenu:
        this.toggleButton === toggleOption.WelbeeVoice
          ? MenuItems.Conversations
          : '',
      voiceMenu: false,
      schoolLogo: props.logo || null,
      accessLevel: 0,
      isUpgrade: true,
      welbeeType: 0,
      showAccount: true,
      freePeriodEndDate: new Date(),
      role: '',
      isMat: false,
      MatHeatMap: 'Mat Heat Map',
      hasQuestionBank: false,
      isDisplayMatHeatMap: false,
      isPaid: false,
      docked: mql.matches,
      open: false,
      openGuidedTour: false,
      schoolSubscription: '',
      showReportMenu: false,
      hasSummaryReport: false,
    };

    this.onClickHandler = this.onClickHandler.bind(this);
    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
    this.onSetOpen = this.onSetOpen.bind(this);
  }

  setSubscription = (schoolId) => {
    schoolSubscriptionService
      .getSchoolSubscriptionsById(schoolId)
      .then((response) => {
        this.setState({
          schoolSubscription: response.data.SubscriptionId,
        });
        storageService.setItem(
          WebStorageNames.SubscriptionId,
          response.data.SubscriptionId
        );

        if (response.data.MatGroupId == null) {
          storageService.setItem(WebStorageNames.IsDisplayMatHeatMap, 'false');
        }
      })
      .catch((error) => console.log(error));
  };

  componentDidMount() {
    this.getRegions();
    this.checkSummaryReport();
    let schoolToken = getQueryStringVal(QueryStringTokens.schoolToken);
    if (schoolToken) {
      schoolService.getSchool(schoolToken).then((response) => {
        const { data } = response;
        if (data) {
          var schoolId = response.data.Id;
          if (response.data.MatGroupId == null) {
            storageService.setItem(
              WebStorageNames.IsDisplayMatHeatMap,
              'false'
            );
          }

          schoolSubscriptionService
            .getSchoolSubscriptionsById(schoolId)
            .then((response) => {
              this.setState({
                schoolSubscription: response.data.SubscriptionId,
              });

              if (response.data.MatGroupId == null) {
                storageService.setItem(
                  WebStorageNames.IsDisplayMatHeatMap,
                  'false'
                );
              }
            })
            .catch((error) => console.log(error));
        }
      });
    } else {
      const schoolId = storageService.getItem(WebStorageNames.SchoolId);
      this.setSubscription(schoolId);
    }
  }
  getRegions = () => {
    accountService
      .checkCurrentRegion()
      .then((response) => {
        storageService.setItem(WebStorageNames.UsRegion, response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentWillUnmount() {
    mql.removeListener(this.mediaQueryChanged);
  }

  onSetOpen(open) {
    this.setState({ open });
  }

  mediaQueryChanged() {
    this.setState({
      docked: mql.matches,
      open: false,
    });
  }

  toggleOpen(ev) {
    this.setState({ open: !this.state.open });

    if (ev) {
      ev.preventDefault();
    }
  }
  checkSummaryReport() {
    const schoolId = storageService.getItem(WebStorageNames.SchoolId);
    surveyReportService.checkSummaryReport(schoolId).then((response) => {
      this.setState({ hasSummaryReport: response.data });
    });
    //this.setState({ hasSummaryReport: true });
  }

  componentWillMount() {
    if (!isEmpty(window.location.search)) {
      let surveyToken = getQueryStringVal(QueryStringTokens.token);
      if (surveyToken != null) {
        storageService.setItem(WebStorageNames.SurveyToken, surveyToken);
      }
      let currentSchoolToken = getQueryStringVal(QueryStringTokens.schoolToken);
      if (currentSchoolToken != null && currentSchoolToken != 'null') {
        storageService.setItem(WebStorageNames.SchoolToken, currentSchoolToken);
      }
    }
    IsActionAllowed(RestrictedActions.QuestionBank)
      .then((response) => this.setState({ hasQuestionBank: response.data }))
      .catch();
    IsActionAllowed(RestrictedActions.Report)
      .then((response) => this.setState({ isPaid: response.data }))
      .catch();
    //let schoolToken = storageService.getItem(WebStorageNames.SchoolToken);
    let schoolToken = getQueryStringVal(QueryStringTokens.schoolToken);
    let userToken = getQueryStringVal(QueryStringTokens.token);
    let welbeeType = storageService.getItem(WebStorageNames.SubscriptionId);
    this.setState({ welbeeType: welbeeType });
    let schoolLogoUrl =
      this.props.logo || storageService.getItem(WebStorageNames.schoolLogoUrl);
    const accessLevel = storageService.getItem(WebStorageNames.AccessLevel);
    const isStaff = storageService.getItem(WebStorageNames.IsStaff);
    const freeTrialEndDate = storageService.getItem(
      WebStorageNames.FreeTrialEndDate
    );
    const IsUpgradBannderDisplayed = storageService.getItem(
      WebStorageNames.IsUpgradBannderDisplayed
    );
    const role = storageService.getItem(WebStorageNames.Role);
    const isMat = storageService.getItem(WebStorageNames.IsMat);

    this.setState({
      isDisplayMatHeatMap: storageService.getItem(
        WebStorageNames.IsDisplayMatHeatMap
      ),
    });
    console.log('schoolLogoUrl', schoolLogoUrl);
    this.setState({ schoolLogo: schoolLogoUrl, role: role });
    if (
      welbeeType === WelbeeTypes.WelbeeVoiceFree ||
      welbeeType === WelbeeTypes.WelbeeVoicePro
    ) {
      this.setState({
        voiceMenu: true,
        isUpgrade: true,
        freePeriodEndDate: freeTrialEndDate,
        IsUpgradBannderDisplayed: IsUpgradBannderDisplayed,
        navActiveMenu: MenuItems.UserGuide,
      });
      this.props.actions.toggleButtonSwitch(toggleOption.WelbeeVoice);
    }
    if (welbeeType === WelbeeTypes.WelbeeVoicePro) {
      this.setState({
        voiceMenu: true,
        navActiveMenu: MenuItems.UserGuide,
      });
      this.props.actions.toggleButtonSwitch(toggleOption.WelbeeVoice);
    }
    const selectedTab = storageService.getItem(WebStorageNames.SelectedTab);
    if (selectedTab === 1) {
      this.props.actions.toggleButtonSwitch(toggleOption.WelbeeVoice);
    } else {
      this.props.actions.toggleButtonSwitch(toggleOption.WelbeeSurvey);
    }
    this.setState({
      accessLevel: accessLevel,
      welbeeType: welbeeType,
      isStaff: isStaff,
      isMat: isMat,
    });
    this.countDown();

    staffResultServices
      .getGroupName(schoolToken, userToken)
      .then((response) => {
        const { success, data } = response;
        if (success) {
          this.setState({
            MAT: data.GroupName,
          });
        }
      })
      .catch();

    //This is copied here from duplicated ConpunentWillMount function for code refactoring
    mql.addListener(this.mediaQueryChanged);
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.logo !== this.props.logo) {
      this.setState({
        schoolLogo: nextProps.logo,
      });
    }
  };

  countDown() {
    let secound = 5;
    let myInterval = setInterval(() => {
      if (secound > 0) {
        secound = secound - 1;
      }
      if (secound === 0) {
        this.setState({ showAccount: false });
        storageService.setItem(WebStorageNames.IsUpgradBannderDisplayed, true);
        clearInterval(myInterval);
      }
    }, 1000);
  }

  redirectToRoute(routeName) {
    let schoolToken = getQueryStringVal(QueryStringTokens.schoolToken);
    let surveyToken = storageService.getItem(WebStorageNames.SurveyToken);
    if (schoolToken) {
      schoolService.getSchool(schoolToken).then((response) => {
        const route = routeName.split('?')[0];
        const { data } = response;
        if (response.data.MatGroupId == null) {
          storageService.setItem(WebStorageNames.IsDisplayMatHeatMap, 'false');
        }
        if (data) {
          //var obj = storageService.getItem(`cache_${response.data.Name}`);
          //const { surveyToken } = obj;

          this.props.history.push(
            `${route}?token=${surveyToken}&schoolToken=${schoolToken}`
          );
        } else {
          this.props.history.push(`${route}?schoolToken=${schoolToken}`);
        }
      });
    } else {
      this.props.history.push(routeName);
    }
  }

  redirectToRouteWithToken(routeName) {
    let schoolToken = getQueryStringVal(QueryStringTokens.schoolToken);
    if (schoolToken) {
      schoolService.getSchool(schoolToken).then((response) => {
        const { data } = response;
        if (response.data.MatGroupId == null) {
          storageService.setItem(WebStorageNames.IsDisplayMatHeatMap, 'false');
        }
        if (data) {
          var obj = storageService.getItem(`cache_${response.data.Name}`);
          const { schoolToken, surveyToken } = obj;
          this.redirectToRoute(
            routeName + '?schoolToken=' + schoolToken + '&token=' + surveyToken
          );
        } else {
          let surveyToken = storageService.getItem(WebStorageNames.SurveyToken);
          this.redirectToRoute(routeName + '?token=' + surveyToken);
        }
      });
    } else {
      let surveyToken = storageService.getItem(WebStorageNames.SurveyToken);
      this.redirectToRoute(routeName + '?token=' + surveyToken);
    }
  }

  redirectToRouteWithSchoolId(routeName) {
    let schoolId = storageService.getItem(WebStorageNames.SchoolId);
    this.redirectToRoute(routeName + '?schoolId=' + schoolId);
  }

  redirectToRouteWithSchoolToken(routeName) {
    let schoolToken = getQueryStringVal(QueryStringTokens.schoolToken);
    var route = `${routeName}?schoolToken=${schoolToken}&IsMatSchoolFromHeatmap=false`;
    console.log('route', route);
    this.redirectToRoute(route);
  }

  redirectToRouteWithSurveyandSchoolToken(routeName, schoolTokenParam) {
    console.log('schoolTokenParam', schoolTokenParam);
    let surveyToken = storageService.getItem(WebStorageNames.SurveyToken);
    if (schoolTokenParam)
      schoolService.getSchool(schoolTokenParam).then((response) => {
        // var obj = storageService.getItem(`cache_${response.data.Name}`);
        // const { schoolToken, surveyToken } = obj;
        var route = `${routeName}?schoolToken=${schoolTokenParam}&token=${surveyToken}`;
        console.log('route', route);
        this.redirectToRoute(route);
      });
  }

  onClickHandler = ({ name, showReportSubMenu }) => {
    if (name === MenuItems.Report && this.props.disableReportMenu === true)
      return;
    this.props.actions.activeItemAction({
      item: name,
      showReportSubMenu,
    });
  };

  onClickHandlerReportMenu = ({ name, showReportMenu }) => {
    if (name === MenuItems.Report && this.props.disableReportMenu === true)
      return;

    this.setState({ showReportMenu: showReportMenu });
  };
  onLogoutClickHandler = () => {
    storageService.clear();
    this.props.history.push(routesObj.Login.path);
  };

  navigateBackToReport() {
    const pathName = window.location.pathname;
    if (pathName === routesObj.ManagementStandards.path) {
      this.redirectToRouteWithToken(routesObj.SurveyReport.path);
    }
  }

  ontoggleButtonSwitch = (option) => {
    storageService.setItem(WebStorageNames.SelectedTab, option);
    this.props.actions.toggleButtonSwitch(option);
  };
  schoolTokenExist() {}
  getStaffManagementMenu(eventName, navActiveMenu) {
    return (
      <li className='voice-nav menu-staff'>
        <a
          href
          onClick={() => {
            this.onClickHandler({
              name: MenuItems.StaffManagement,
              showReportSubMenu: false,
            });
            amplitudeLog(eventName, 'SideMenu' + eventName, '');
            if (this.state.role === Roles.GroupDirector) {
              this.redirectToRouteWithSchoolToken(
                routesObj.StaffManagement.path
              );
            } else this.redirectToRoute(routesObj.StaffManagement.path);
          }}
          className={
            navActiveMenu === MenuItems.StaffManagement ? 'active' : ''
          }
        >
          <img src={iconStaffManagement} alt='' /> <span>Staff Management</span>
        </a>
      </li>
    );
  }

  TourClickHandler = () => {
    this.setState({ openGuidedTour: true });
  };

  closeGuidedTour = () => {
    this.setState({ openGuidedTour: false });
  };

  toggleTour() {
    if (
      this.state.schoolSubscription !== 3 &&
      this.state.schoolSubscription !== 1
    ) {
      return (
        <li className='btn-tour'>
          <a
            href
            onClick={() => {
              this.TourClickHandler();
            }}
          >
            Start Tour
          </a>
        </li>
      );
    }
  }

  getLogoutMenu() {
    return (
      <li className='btn-logout'>
        <a
          href
          onClick={() => {
            this.onLogoutClickHandler();
          }}
        >
          Logout
        </a>
      </li>
    );
  }

  getUpgradePlan() {
    return this.state.welbeeType === WelbeeTypes.WelbeeSurveyPremium ? null : (
      <li className='btn-upgrade'>
        <a
          href
          onClick={() => {
            this.props.history.push(routesObj.AccountSubscription.path);
          }}
        >
          Upgrade Plan
        </a>
      </li>
    );
  }

  onUpgradeXClick = () => {
    storageService.setItem(WebStorageNames.IsUpgradeDisplay, false);
    this.setState({ isUpgrade: false });
  };

  getDashOffSet = () => {
    const totalNoOfDaysLeft = freePeriodNoOfDaysLeft(
      this.state.freePeriodEndDate
    );
    return 'calc(' + 7 * (63 - totalNoOfDaysLeft) + ')';
  };

  getMatAnalyticsMenu = () => {
    const { isMat, accessLevel } = this.state;
    const { isDisplayMatHeatMap } = this.state;
    const { navActiveMenu } = this.props;
    const matGroupId = storageService.getItem(WebStorageNames.MatGroupId);

    if (
      (accessLevel === HeadTeacherAccess.Full &&
        isMat &&
        isDisplayMatHeatMap) ||
      (matGroupId !== null && isDisplayMatHeatMap)
    ) {
      return (
        <li>
          <a
            href
            onClick={() => {
              this.onClickHandler({
                name: MenuItems.MATAnalytics,
                showReportSubMenu: false,
              });
              let schoolToken = getQueryStringVal(
                QueryStringTokens.schoolToken
              );
              if (
                this.state.role === Roles.GroupDirector ||
                schoolToken !== null
              ) {
                this.redirectToRouteWithSchoolToken(
                  routesObj.MATAnalytics.path
                );
              } else {
                this.redirectToRoute(routesObj.MATAnalytics.path);
              }
            }}
            className={navActiveMenu === MenuItems.MATAnalytics ? 'active' : ''}
          >
            {this.state.MAT}
          </a>
        </li>
      );
    }
  };
  getMatMenu = () => {
    const { isMat, accessLevel } = this.state;
    const { isDisplayMatHeatMap } = this.state;
    const { navActiveMenu } = this.props;
    const matGroupId = storageService.getItem(WebStorageNames.MatGroupId);

    if (
      (accessLevel === HeadTeacherAccess.Full &&
        isMat &&
        isDisplayMatHeatMap) ||
      (matGroupId !== null && isDisplayMatHeatMap)
    ) {
      return (
        <li>
          <a
            href
            onClick={() => {
              this.onClickHandler({
                name: MenuItems.MatHeatMap,
                showReportSubMenu: false,
              });
              let schoolToken = getQueryStringVal(
                QueryStringTokens.schoolToken
              );
              if (
                this.state.role === Roles.GroupDirector ||
                schoolToken !== null
              ) {
                this.redirectToRouteWithSchoolToken(routesObj.MatHeatMap.path);
              } else {
                this.redirectToRoute(routesObj.MatHeatMap.path);
              }
            }}
            className={navActiveMenu === MenuItems.MatHeatMap ? 'active' : ''}
          >
            <img src={iconHeatmap} alt='' /> {this.state.MAT}
          </a>
        </li>
      );
    }
  };

  render() {
    const contentHeader = (
      <div className='main-header-content'>
        <div>
          {!this.state.docked && (
            <a
              onClick={this.toggleOpen}
              href='#'
              style={styles.contentHeaderMenuLink}
            >
              <MenuIcon />
            </a>
          )}
        </div>

        <div className='mobile-logo'>
          <img
            src={
              this.state.schoolLogo !== null
                ? this.state.schoolLogo
                : welbeeDashboardLogo
            }
            alt=''
          />
        </div>
        <Box
          sx={{
            display: {
              xs: 'block',
              sm: 'block',
              md: 'block',
              xl: 'none',
              lg: 'none',
            },
          }}
        >
          <div className='btn-request-assistance'>
            <RequestAssistanceButton />
          </div>
        </Box>
      </div>
    );

    const {
      activeItem: navActiveMenu,
      showReportSubMenu,
      newCommentCount,
      toggleButton,
      newConversationCount,
      logoURL,
    } = this.props;
    const {
      accessLevel,
      isUpgrade,
      welbeeType,
      isStaff,
      showAccount,
      freePeriodEndDate,
      IsUpgradBannderDisplayed,
      isMat,
      schoolSubscription,
      showReportMenu,
    } = this.state;
    const pathName = window.location.pathname;
    const eventName =
      pathName === '/survey-report'
        ? 'Report'
        : pathName === '/dashboard'
        ? 'Dashboard'
        : pathName === '/staff-presentation'
        ? 'Presentation'
        : pathName === '/survey-comments'
        ? 'Comments'
        : pathName === '/learn-more'
        ? 'LearnMore'
        : pathName === '/account'
        ? 'account'
        : pathName === '/staff-management'
        ? 'StaffManagement'
        : 'Dashboard';
    return (
      <div>
        <Sidebar
          className='leftsidebar'
          sidebar={
            <Scrollbars
              className='scrollbar-view'
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
            >
              <div className='leftsidemenu'>
                <ul data-id-ul='reportLeftMenus'>
                  <li align='center'>
                    <img
                      src={
                        this.state.schoolLogo !== null
                          ? this.state.schoolLogo
                          : welbeeDashboardLogo
                      }
                      alt=''
                      className='school-logo'
                    />
                  </li>

                  {toggleButton === toggleOption.WelbeeSurvey ? (
                    <>
                      <AccessLevel
                        permissions={[
                          AccessPermissions.Full,
                          AccessPermissions.HeadTeacher,
                          AccessPermissions.View,
                          AccessPermissions.Admin,
                        ]}
                      >
                        <li className='menu-dashboard'>
                          <a
                            href
                            onClick={() => {
                              this.onClickHandler({
                                name: MenuItems.Dashboard,
                                showReportSubMenu: false,
                              });

                              let schoolToken = getQueryStringVal(
                                QueryStringTokens.schoolToken
                              );
                              if (
                                this.state.role === Roles.GroupDirector ||
                                schoolToken != null
                              ) {
                                this.redirectToRouteWithSchoolToken(
                                  routesObj.StudentList.path
                                );
                              } else {
                                if (accessLevel === HeadTeacherAccess.Admin) {
                                  this.redirectToRoute(
                                    routesObj.StudentList.path
                                  );
                                } else
                                  this.redirectToRoute(
                                    routesObj.StudentList.path
                                  );
                              }
                              amplitudeLog(
                                eventName,
                                'SideMenu' + eventName,
                                ''
                              );
                            }}
                            className={
                              navActiveMenu === MenuItems.Dashboard
                                ? 'active'
                                : ''
                            }
                          >
                            <img src={iconDashboard} alt='' />{' '}
                            <span>Student</span>
                          </a>
                        </li>
                      </AccessLevel>

                      <AccessLevel
                        permissions={[
                          AccessPermissions.Full,
                          AccessPermissions.HeadTeacher,
                          AccessPermissions.View,
                        ]}
                      >
                        <li className='menu-analytics'>
                          <a
                            href
                            onClick={() => {
                              this.onClickHandler({
                                name: MenuItems.Analytics,
                                showReportSubMenu: false,
                              });
                              let schoolToken = getQueryStringVal(
                                QueryStringTokens.schoolToken
                              );
                              if (
                                this.state.role === Roles.GroupDirector ||
                                schoolToken != null
                              ) {
                                this.redirectToRouteWithSchoolToken(
                                  routesObj.StudentSyncSetting.path
                                );
                              } else {
                                if (accessLevel === HeadTeacherAccess.Admin) {
                                  this.redirectToRoute(
                                    routesObj.StudentSyncSetting.path
                                  );
                                } else
                                  this.redirectToRoute(
                                    routesObj.StudentSyncSetting.path
                                  );
                              }
                              amplitudeLog(
                                eventName,
                                'SideMenu' + eventName,
                                ''
                              );
                            }}
                            className={
                              navActiveMenu === MenuItems.Analytics
                                ? 'active'
                                : ''
                            }
                          >
                            <img src={iconAnalytics} alt='' />{' '}
                            <span>Sync Setting</span>
                          </a>
                        </li>
                      </AccessLevel>

                      {this.getMatMenu()}

                      {this.getStaffManagementMenu(eventName, navActiveMenu)}

                      <div className='btn-bottom'>
                        {/* {this.toggleTour()}
                        {this.getUpgradePlan()} */}
                        {this.getLogoutMenu()}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </ul>
              </div>
            </Scrollbars>
          }
          open={this.state.open}
          docked={this.state.docked}
          onSetOpen={this.onSetOpen}
        >
          <div className='main-header'>{contentHeader}</div>
        </Sidebar>
      </div>
    );
  }
}
const mapStateToProps = ({
  header: {
    activeItem,
    showReportSubMenu,
    disableReportMenu,
    newCommentCount,
    toggleButton,
    newConversationCount,
    logoURL,
  },
  user: { freeTrailEndDate },
}) => ({
  activeItem,
  showReportSubMenu,
  disableReportMenu,
  newCommentCount,
  toggleButton,
  newConversationCount,
  logoURL,
  freeTrailEndDate,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(navigationActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LeftMenu));
