import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { PasswordReg } from '../../utils';
import { ExtendedWrapperMain } from '../../resources/styling/formStyle';
import { toast } from 'react-toastify';
import { ErrorWrapper, FieldWrapper, AppButton } from '../../resources/styling';
import { WelbeeTextField } from '../../resources/styling/appStyle';
import { colors } from '../../resources/theme/colors';
import { schoolService, accountService } from '../../services';
import { loaderAction } from '../../store/loaderStore';
import { PasswordViewIcon } from '../../components/common/loginStyle';
import { loginNormalEye, loginShowEye } from '../../resources/images/index';
import Grid from '@mui/material/Grid';
import AccessLevel from '../../components/common/AccessLevel';
import { AccessPermissions, Roles } from '../../utils/Constants';
import ConfirmPopupForm from '../../components/common/ConfirmPopupForm';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { Stack } from '@mui/system';
import wondeService from '../../services/api/wondeService';

const { mandy } = colors;

class AdminInfoForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SchoolRecord: null,
      errorMessage: '',
      isPasswordShow: true,
      isRequestAccessPopupOpen: false,
      isRequestAccessBtnShow: false,
      isRequestStatus: 0,
      isSyncedPopupOpen: false,
      currentSchoolUrnNo: 0,
      confirmPasswordErrorMessage: '',
    };
  }

  componentWillReceiveProps(props) {
    debugger;
    this.setState({ SchoolRecord: props.SchoolRecord });
    this.setState({
      currentSchoolUrnNo: props.SchoolRecord?.school.WondeeSchoolUrn,
    });

    this.getWondeRequestStatus(props.SchoolRecord?.school.WondeeSchoolUrn);
  }

  getWondeRequestStatus = (wondeSchoolUrn) => {
    wondeService
      .getWondeSchoolRequestStatus(wondeSchoolUrn)
      .then((response) => {
        const { success } = response;
        if (success) {
          if (response.data.WondeRequestStatus === 0) {
            this.setState({ isRequestAccessBtnShow: true });
          }

          this.setState({ isRequestStatus: response.data.WondeRequestStatus });
        }
      })
      .catch((error) => {});
  };

  handleRequestAccess = (id) => {
    wondeService
      .updateWondeRequestStatus(id)
      .then((response) => {
        const { success } = response;
        if (success) {
          toast.success('Request Access has been sended  successfully.');

          this.setState({ isRequestAccessBtnShow: false });
          this.setState({ isRequestAccessPopupOpen: false });
          this.getWondeRequestStatus(
            this.props.SchoolRecord?.school.WondeeSchoolUrn
          );
        }
      })
      .catch((error) => {});
  };

  onRequestAccessClick = (e) => {
    e.stopPropagation();
    this.setState({ isRequestAccessPopupOpen: true });
  };

  handleSyncedFromWonde = (id) => {
    wondeService
      .updateWondeSyncedStatus(id)
      .then((response) => {
        const { success } = response;
        if (success) {
          toast.success(
            'Synced Staatus has been updated  successfully. Once Service will run it will automatically synced the data.'
          );
          this.setState({ isSyncedPopupOpen: false });
        }
      })
      .catch((error) => {});
  };

  onSyncedFromWondeClick = (e) => {
    e.stopPropagation();
    this.setState({ isSyncedPopupOpen: true });
  };

  render() {
    const { SchoolRecord } = this.state;
    return (
      <Formik
        enableReinitialize
        initialValues={{
          Id: SchoolRecord ? SchoolRecord.school.Id : 0,
          Email:
            SchoolRecord && SchoolRecord.school
              ? SchoolRecord.school.Email != null
                ? SchoolRecord.school.Email
                : ''
              : '',
          FirstName:
            SchoolRecord && SchoolRecord.headTeacher
              ? SchoolRecord.headTeacher.FirstName != null
                ? SchoolRecord.headTeacher.FirstName
                : ''
              : '',
          LastName:
            SchoolRecord && SchoolRecord.headTeacher
              ? SchoolRecord.headTeacher.LastName != null
                ? SchoolRecord.headTeacher.LastName
                : ''
              : '',
          Password: '',
          IsUpdateEnabled: false,
        }}
        validateOnBlur={true}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          Email: Yup.string()
            .trim()
            .email('Provide email address like example@g.com.')
            .required('Admin email is required.'),
          FirstName: Yup.string().trim().required('First name is required.'),
          LastName: Yup.string().trim().required('Last name is required.'),
          Password: Yup.string()
            .trim()
            .min(12, 'Password must be at least 12 characters.')
            .max(50, 'Password less then 50 characters.')
            .matches(
              PasswordReg,
              'Your password must contain at least 1 uppercase, lowercase, number and special character from @£!#$%^&+="\',-.'
            ),
          ConfirmPassword: Yup.string().trim(),
        })}
        onSubmit={(values) => {
          console.log(values);
          if (
            (values.Password !== '' &&
              values.Password === values.ConfirmPassword) ||
            values.Password === ''
          ) {
            const { loaderActions } = this.props;
            loaderActions.dataLoading(true);
            schoolService
              .updateSchoolAdminInfoByHeadTeacher(values)
              .then((response) => {
                if (response.success) {
                  loaderActions.dataLoading(false);
                  toast.success(
                    'Account information has been updated successfully.'
                  );
                } else {
                  loaderActions.dataLoading(false);
                  this.duplicationErrorMeassageHandle(response.message);
                }
              })
              .catch((error) => {
                loaderActions.dataLoading(false);
              });
          } else {
            this.setState({
              confirmPasswordErrorMessage: 'Passwords must match.',
            });
          }
        }}
        render={({
          values,
          touched,
          errors,
          handleSubmit,
          setFieldValue,
          handleBlur,
          handleChange,
        }) => (
          <div className='search-inner-sub-container'>
            <h2> Staff Sync</h2>

            <div className='form-body'>
              <Grid container spacing={2}>
                {/* <Grid item md={6} xs={12}>
                  <ExtendedWrapperMain>
                    <WelbeeTextField
                      fullWidth
                      label='First Name'
                      name='First name'
                      value={values.FirstName}
                      onChange={(e) => {
                        setFieldValue('FirstName', e.target.value);
                        setFieldValue('IsUpdateEnabled', true);
                      }}
                    />
                    <ErrorWrapper>
                      {touched.FirstName && errors.FirstName}
                    </ErrorWrapper>
                  </ExtendedWrapperMain>
                </Grid>
                <Grid item md={6} xs={12}>
                  <ExtendedWrapperMain>
                    <WelbeeTextField
                      fullWidth
                      label='Last Name'
                      name='Last name'
                      value={values.LastName}
                      onChange={(e) => {
                        setFieldValue('LastName', e.target.value);
                        setFieldValue('IsUpdateEnabled', true);
                      }}
                    />
                    <ErrorWrapper>
                      {touched.FirstName && errors.FirstName}
                    </ErrorWrapper>
                  </ExtendedWrapperMain>
                </Grid>
                <Grid item md={12} xs={12} style={{ paddingTop: '0' }}>
                  <ExtendedWrapperMain>
                    <WelbeeTextField
                      fullWidth
                      label='Email'
                      name='Email'
                      value={values.Email}
                      onChange={(e) => {
                        setFieldValue('Email', e.target.value);
                        setFieldValue('IsUpdateEnabled', true);
                      }}
                    />
                    <ErrorWrapper>{touched.Email && errors.Email}</ErrorWrapper>
                  </ExtendedWrapperMain>
                </Grid> */}
                <AccessLevel
                  permissions={[
                    AccessPermissions.Full,
                    AccessPermissions.HeadTeacher,
                  ]}
                >
                  {/* test btn start  */}
                  {/* <Grid item md={12} xs={12} style={{ paddingTop: '0' }}> */}
                  <Grid item lg={8} md={8} xs={12}>
                    {/* <AccessLevel
                      permissions={[
                        AccessPermissions.Full,
                        AccessPermissions.HeadTeacher,
                        AccessPermissions.Admin,
                      ]}
                    > */}
                    <Stack
                      direction='row'
                      justifyContent={'flex-start'}
                      spacing={2}
                    >
                      {this.state.isRequestAccessBtnShow ? (
                        <Button
                          style={{
                            backgroundColor: '#007A7A',
                          }}
                          //startIcon={<UploadIcon />}
                          variant='contained'
                          disableElevation
                          component='span'
                          onClick={this.onRequestAccessClick}
                          // disabled={
                          //   loggedInRole === Roles.GroupDirector ? 'disabled' : null
                          // }
                        >
                          REQUEST ACCESS
                        </Button>
                      ) : this.state.isRequestStatus === 1 ||
                        this.state.isRequestStatus === 2 ? (
                        <Button
                          style={{
                            backgroundColor: '#e84f6b',
                          }}
                          variant='contained'
                          disableElevation
                        >
                          Response Pending from Wonde
                        </Button>
                      ) : null}

                      {this.state.currentSchoolUrnNo > 0 &&
                      this.state.isRequestStatus === 3 ? (
                        <Button
                          style={{
                            backgroundColor: '#E84F6B',
                          }}
                          variant='contained'
                          disableElevation
                          onClick={this.onSyncedFromWondeClick}
                        >
                          Synced From Wonde
                        </Button>
                      ) : this.state.currentSchoolUrnNo > 0 &&
                        this.state.isRequestStatus === 4 ? (
                        <Button
                          style={{
                            backgroundColor: '#45338c',
                          }}
                          variant='contained'
                          disableElevation
                        >
                          Synced
                        </Button>
                      ) : null}
                    </Stack>
                    {/* </AccessLevel> */}
                  </Grid>

                  {/* </Grid> */}

                  {this.state.isRequestAccessPopupOpen ? (
                    <ConfirmPopupForm
                      isOpen={this.state.isRequestAccessPopupOpen}
                      onClose={() => {
                        this.setState({ isRequestAccessPopupOpen: false });
                      }}
                      onClick={this.onSyncedFromWondeClick}
                      onOk={() => {
                        this.handleRequestAccess(this.state.currentSchoolUrnNo);
                      }}
                      successPopupMessage='Are you sure to send request for wonde sync for this school?'
                    />
                  ) : (
                    <div></div>
                  )}

                  {this.state.isSyncedPopupOpen ? (
                    <ConfirmPopupForm
                      isOpen={this.state.isSyncedPopupOpen}
                      onClose={() => {
                        this.setState({ isSyncedPopupOpen: false });
                      }}
                      onClick={this.onSyncedFromWondeClick}
                      onOk={() => {
                        this.handleSyncedFromWonde(
                          this.state.currentSchoolUrnNo
                        );
                      }}
                      successPopupMessage='Are you sure to Sync data from wonde?'
                    />
                  ) : (
                    <div></div>
                  )}

                  {/* test btn end  */}
                </AccessLevel>
              </Grid>
            </div>
          </div>
        )}
      ></Formik>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(accountService, dispatch),
  loaderActions: bindActionCreators(loaderAction, dispatch),
});

export default connect(null, mapDispatchToProps)(AdminInfoForm);
