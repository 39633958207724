import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { amplitudeLog } from '../../utils/CommonFunctions';
import {
  AppButton,
  LoginFormWrapper,
  ErrorWrapper,
  WelbeeTextField,
} from '../../resources/styling/appStyle';
import { loginNormalEye, loginShowEye } from '../../resources/images/index';
import Grid from '@mui/material/Grid';
import { colors } from '../../resources/theme/colors';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { accountService, storageService } from '../../services';
import { routesObj } from '../../routes';
import { loaderAction } from '../../store/loaderStore';
import { firstLoginAction } from '../../store/userStore';
import {
  WebStorageNames,
  Roles,
  toggleOption,
  HeadTeacherAccess,
  WelbeeTypes,
} from '../../utils/Constants';
import { parseJwt } from '../../utils/CommonFunctions';
import { Redirect } from 'react-router-dom';
import LoginWrapper from '../../components/common/LoginWrapper';
import { WrapperMain } from '../../resources/styling/formStyle';
import {
  LoginFormInner,
  InputWrapper,
  FieldWrapper,
  PasswordViewIcon,
} from '../../components/common/loginStyle';
import i18next from 'i18next';
import { usePermission } from 'react-permission-role';

const { mandy } = colors;

function Login(props) {
  let [isPasswordShow, setIsPasswordShow] = useState(true);
  let [emailErrorMessage, setEmailErrorMessage] = useState('');
  let [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  let [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { setUser } = usePermission();

  const getRegions = () => {
    accountService
      .checkCurrentRegion()
      .then((response) => {
        storageService.setItem(WebStorageNames.UsRegion, response.data);
        i18next.changeLanguage(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    //storageService.clear();
    getRegions();
    amplitudeLog('PageView', 'Login', '');
  }, []);
  return (
    <Formik
      initialValues={{
        Email: '',
        Password: '',
      }}
      onSubmit={(values, actions) => {
        setPasswordErrorMessage('');
        setEmailErrorMessage('');
        setIsButtonDisabled(true);
        props.loaderActions.dataLoading(true);
        getRegions();
        setTimeout(() => {
          actions.setSubmitting(false);
          accountService
            .login(values)
            .then((response) => {
              const { success, message } = response;
              props.loaderActions.dataLoading(false);
              if (success) {
                storageService.setToken({
                  token: response.data.access_token,
                });
                storageService.setItem(
                  WebStorageNames.IsDisplayMatHeatMap,
                  response.data.IsDisplayMatHeatMap
                );
                storageService.setItem(
                  WebStorageNames.SubscriptionId,
                  response.data.SubscriptionId
                );

                const userInfo = parseJwt(response.data.access_token);
                storageService.setItem(
                  WebStorageNames.UserInfo,
                  response.data.userInfo
                );
                storageService.setItem(
                  WebStorageNames.SelectedTab,
                  response.data.SubscriptionId ===
                    WelbeeTypes.WelbeeVoiceFree ||
                    response.data.SubscriptionId === WelbeeTypes.WelbeeVoicePro
                    ? toggleOption.WelbeeVoice
                    : toggleOption.WelbeeSurvey
                );
                if (userInfo.role === Roles.GroupDirector) {
                  storageService.setItem(WebStorageNames.IsMat, true);
                  storageService.setItem(
                    WebStorageNames.MatGroupId,
                    response.data.MatGroupId
                  );
                  props.history.push(routesObj.StudentList.path);
                  storageService.setItem(
                    WebStorageNames.Role,
                    Roles.GroupDirector
                  );
                }
                storageService.setItem(
                  WebStorageNames.SchoolId,
                  response.data.schoolId
                );
                storageService.setItem(
                  WebStorageNames.SchoolName,
                  response.data.schoolName
                );
                const token = storageService.getItem(WebStorageNames.Token);
                if (!token) {
                  return <Redirect to={routesObj.Login.path} />;
                }
                // Settings permissions
                setUser({
                  id: userInfo?.id,
                  roles: [userInfo?.role],
                  permissions: response?.data?.Permissions,
                });

                if (userInfo.role === Roles.SuperAdmin) {
                  storageService.setItem(
                    WebStorageNames.Role,
                    Roles.SuperAdmin
                  );
                  props.history.push(routesObj.StudentList.path);
                }
                storageService.setItem(
                  WebStorageNames.AccessLevel,
                  response.data.AccessLevel
                );
                storageService.setItem(
                  WebStorageNames.IsStaff,
                  response.data.IsStaff
                );
                storageService.setItem(
                  WebStorageNames.WelbeeVoiceAccountType,
                  response.data.WelbeeVoiceAccountType
                );
                storageService.setItem(
                  WebStorageNames.IsFirstLogin,
                  response.data.IsFirstLogin
                );
                storageService.setItem(
                  WebStorageNames.IsVoiceFirstLogin,
                  response.data.IsVoiceFirstLogin
                );
                storageService.setItem(
                  WebStorageNames.FreeTrialEndDate,
                  response.data.FreeTrialEndDate
                );
                storageService.setItem(
                  WebStorageNames.SelectedTab,

                  response.data.SubscriptionId ===
                    WelbeeTypes.WelbeeVoiceFree ||
                    response.data.SubscriptionId === WelbeeTypes.WelbeeVoicePro
                    ? toggleOption.WelbeeVoice
                    : toggleOption.WelbeeSurvey
                );
                storageService.setItem(
                  WebStorageNames.IsUpgradeDisplay,
                  response.data.IsUpgradeDisplay
                );
                storageService.setItem(
                  WebStorageNames.IsUpgradBannderDisplayed,
                  false
                );
                if (userInfo.role === Roles.HeadTeacher) {
                  amplitudeLog(
                    'HeadTeacherLogin',
                    response.data.schoolName + ' HeadTeacherLogin',
                    {
                      UserName: values.Email,
                      SchoolName: response.data.schoolName,
                    }
                  );
                  storageService.setItem(
                    WebStorageNames.WelbeeType,
                    response.data.WelbeeType
                  );
                  storageService.setItem(
                    WebStorageNames.IsMat,
                    response.data.MatGroupId !== null
                  );
                  storageService.setItem(
                    WebStorageNames.MatGroupId,
                    response.data.MatGroupId
                  );
                  storageService.setItem(
                    WebStorageNames.Role,
                    Roles.HeadTeacher
                  );
                  //Code Here
                  // storageService.setItem(
                  //   WebStorageNames.Permissions,
                  //   response.data.Permissions
                  // );
                  if (response.data.AccessLevel === HeadTeacherAccess.Admin) {
                    //if (response.data.WelbeeType === WelbeeType.WelbeeVoice) {
                    if (
                      response.data.SubscriptionId ===
                        WelbeeTypes.WelbeeVoiceFree ||
                      response.data.SubscriptionId ===
                        WelbeeTypes.WelbeeVoicePro
                    ) {
                      props.history.push(routesObj.Account.path);
                    } else {
                      props.history.push(routesObj.StudentList.path);
                    }
                  } else {
                    //if (response.data.WelbeeType === WelbeeType.WelbeeVoice)
                    if (
                      response.data.SubscriptionId ===
                        WelbeeTypes.WelbeeVoiceFree ||
                      response.data.SubscriptionId ===
                        WelbeeTypes.WelbeeVoicePro
                    ) {
                      //props.history.push(routesObj.WelbeeVoice.path);
                    } else
                      props.history.push(
                        routesObj.StudentList.path +
                          '?schoolToken=' +
                          response.data.SchoolToken
                      );
                  }
                }
                amplitudeLog('Login', 'Login button success', {
                  UserName: values.Email,
                });
              } else {
                if (message === 'Invalid email address, user does not exist') {
                  setEmailErrorMessage(message);
                  setIsButtonDisabled(false);
                } else {
                  setPasswordErrorMessage(message);
                  setIsButtonDisabled(false);
                }
                amplitudeLog('Login', 'Login button failure', {
                  UserName: values.Email,
                });
              }
            })
            .catch((error) => console.log(error));
          //actions.resetForm();
        }, 1000);
      }}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={Yup.object().shape({
        Email: Yup.string().trim().required('Email or Username is required.'),
        Password: Yup.string().trim().max(50).required('Password is required.'),
      })}
      render={({ values, handleChange, touched, errors, handleSubmit }) => (
        <LoginWrapper>
          <form
            onSubmit={handleSubmit}
            onKeyDown={(e) => {
              if (e.keyCode === 13) handleSubmit();
            }}
          >
            <LoginFormWrapper>
              <LoginFormInner>
                <h2>Welcome back</h2>
                <InputWrapper>
                  <WrapperMain>
                    <WelbeeTextField
                      fullWidth
                      label='Email or Username'
                      placeholder='Email or Username'
                      autoComplete='new-email'
                      name='Email'
                      id='txt-email'
                      value={values.Email}
                      onChange={handleChange}
                      onKeyDown={(key) => console.log(key)}
                      type='email'
                    />
                    <ErrorWrapper>
                      {(touched.Email && errors.Email) || emailErrorMessage}
                    </ErrorWrapper>
                  </WrapperMain>
                  <WrapperMain>
                    <WelbeeTextField
                      fullWidth
                      label='Password'
                      placeholder='Password'
                      autoComplete='new-password'
                      type={isPasswordShow ? 'password' : 'text'}
                      name='Password'
                      id='txt-password'
                      value={values.Password}
                      onChange={handleChange}
                    />
                    <PasswordViewIcon onClick={(e) => e.preventDefault()}>
                      <img
                        src={isPasswordShow ? loginNormalEye : loginShowEye}
                        onClick={(e) => {
                          e.preventDefault();
                          setIsPasswordShow(!isPasswordShow);
                        }}
                        alt=''
                      />
                    </PasswordViewIcon>
                    <ErrorWrapper>
                      {(touched.Password &&
                        errors.Password &&
                        errors.Password) ||
                        passwordErrorMessage}
                    </ErrorWrapper>
                  </WrapperMain>

                  <FieldWrapper>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        lg={6}
                        sm={12}
                        xs={12}
                        md={12}
                        className='forget-password'
                      >
                        <a
                          href
                          onClick={() =>
                            props.history.push(routesObj.ForgotPassword.path)
                          }
                        >
                          Forgot Password
                        </a>
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        sm={12}
                        xs={12}
                        md={12}
                        className='login-btn-wrapper'
                      >
                        <AppButton
                          color={mandy}
                          type='submit'
                          onClick={handleSubmit}
                          disabled={isButtonDisabled}
                          isDisable={isButtonDisabled}
                          id='btn-login'
                        >
                          Login
                        </AppButton>
                      </Grid>
                    </Grid>
                  </FieldWrapper>
                </InputWrapper>
              </LoginFormInner>
            </LoginFormWrapper>
          </form>
        </LoginWrapper>
      )}
    />
  );
}

const mapDispatchToProps = (dispatch) => ({
  loaderActions: bindActionCreators(loaderAction, dispatch),
  userAction: bindActionCreators(firstLoginAction, dispatch),
});
export default connect(null, mapDispatchToProps)(withRouter(Login));
