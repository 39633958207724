import styled from 'styled-components';
import { colors } from '../.././resources/theme/colors';
import Switch from '@mui/material/Switch';

const {
  whiteColor,
  mineShaft,
  teal,
  mandy,
  lighterGrey,
  purple,
  bordergrey,
  pictonBlue
} = colors;

export const PeopleManagement = styled.div`
  .people-tabs{
    margin-bottom: 12px;
    button{
      background-color: ${lighterGrey};
      border: 1px solid ${bordergrey};
      border-radius: 4px;
      min-height: 38px;
      padding: 0;
      text-transform: none;
      font-weight: bold;
      font-size: 0.875rem !important; 
      color: ${mineShaft};     
    }
    .MuiTabs-indicator{
      background-color: transparent;
    }
    .Mui-selected{
      background-color: ${mandy};
      border: 1px solid ${mandy};
      color: ${whiteColor};
    }
    .MuiTabs-flexContainer{
      column-gap: 8px;
    }
  }
  .content-container{
    .tabs-content{
      background-color: ${whiteColor};
      border-radius: 8px;
      padding: 16px;
      box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);      
      .MuiDataGrid-columnHeaders{
        background-color: ${purple};
        color: ${whiteColor};
        font-weight: bold;
        font-size: 0.875rem;  
        min-height: 40px !important;
        max-height: 40px !important;      
      }
      .MuiDataGrid-sortIcon {
        font-size: 15px;
        opacity: 1 !important;
        visibility: visible !important;
        color: ${whiteColor} !important;
      }
      .MuiDataGrid-cell{
        font-weight: 500;        
      }
    }    
  }
  .filter-btn{
    background-color: ${pictonBlue};
    text-transform: none;
    span{
      margin-left: 7px;
    }
    margin-bottom: 15px
  }
  .setting-btn{
    background-color: ${mandy};    
    text-transform: none;
    min-width: 42px;
    padding: 0;
    margin-bottom: 15px
  }
`;

export const SyncSettings = styled.div`
  .sync-tabs{
    margin-bottom: 20px;
    .MuiTabs-root{
      min-height: 30px;
    }
    button{            
      padding: 0 !important;
      text-transform: none;
      font-weight: bold;
      font-size: 0.875rem !important; 
      color: ${mineShaft};  
      max-width: none;
      min-width: auto; 
      min-height: 30px;             
    }
    .MuiTabs-indicator{
      background-color: ${teal};
    }
    .Mui-selected{      
      color: ${teal};
    }
    .MuiTabs-flexContainer{
      column-gap: 30px;
    }
  }
  .content-container{
    .tabs-content{
      background-color: ${whiteColor};
      border-radius: 8px;
      padding: 16px;
      box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
      .MuiTypography-root{
        font-weight: 500;
      }      
      p:first-child{
        margin: 5px 0 0 0;
        padding: 0
        
      }
      .sync-btn{
        background-color: ${mandy};
        text-transform: none;
      }
      .save-btn{
        border-color: ${mandy};
        color: ${mandy};
        text-transform: none;
      }
    }
  }
`;

export const PeopleMgtSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 18,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#007A7A',
        opacity: 1,
        border: 0,        
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: "#e2e2e2",      
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,      
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 14,
    height: 14,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#cccccc',
    opacity: 1,        
  },
}));

export const Header = styled.div`
.header {
  background-color: ${teal} !important;
  box-shadow: none !important;
  padding: 0;
  min-height: 65px;
  justify-content: center;
  position: static;
  .logo{
    img {
      height: 40px;
    }
    span{
      font-size: 1.25rem;
      font-weight: bold;
    }
  }
  .header-middle {
    width: 100%;
    text-align: center;
    button {
      text-transform: none;
      font-weight: 600;
      color: ${whiteColor};
      margin-right: 1.25rem;              
      span{
        margin-left: 0.625rem;
      }
    }
    button:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
}
`;



const PeopleMgtStyle = {
    PeopleManagement,  
    SyncSettings,
    PeopleMgtSwitch,
    Header
};
export default PeopleMgtStyle;
