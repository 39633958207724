import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { routesObj } from '../routes';
import { colors } from '../resources/theme/colors';
import { PageWrapper } from '../resources/styling/appStyle';
import { BaseCSS } from 'styled-bootstrap-grid';
import AuthHOC from '../components/hoc/AuthHOC';
import { UserGuideSurveyLink, UserGuideVoiceLink } from '../utils/Constants';

const { pictonBlue, mineShaft, whiteColor } = colors;

const MainWrapper = styled.div`
  label {
    color: ${pictonBlue} !important;
  }
  input::-webkit-input-placeholder {
    color: ${pictonBlue} !important;
  }
  .floating-input,
  .floating-select {
    border: 1px solid ${pictonBlue} !important;
    color: ${mineShaft} !important;
    margin-bottom: 0;
    background: ${whiteColor};
  }

  .floating-input:focus ~ label,
  .floating-input:not(:placeholder-shown) ~ label {
    color: ${pictonBlue} !important;
  }

  .floating-select:focus ~ label,
  .floating-select:not([value='']):valid ~ label {
    color: ${pictonBlue};
  }
  > div {
    > div {
      max-height: 100vh !important;
      /*overflow-x: hidden !important; */
    }
  }
`;

const customCSS = `
@-ms-viewport {
  width: device-width;
}
html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}
.main-outer-wrapper {
  float: left;
  width: 100%;
}

.welcome-content{
  display: flex;
  align-items: center;
}
.school-name {
  font-weight: 600;
  color: ${pictonBlue} !important;
}
.mobile-wrapper {
  padding: 0;
  padding-bottom: 3.5rem;

}
`;

class Routes extends Component {
  render() {
    return (
      <MainWrapper>
        <Router>
          <Switch>
            {/* ***************** Public Routes */}
            <PublicRoute
              exact
              path={routesObj.Root.path}
              render={() => routesObj.Login.component}
            />

            <PublicRoute
              exact
              path={routesObj.Login.path}
              render={() => routesObj.Login.component}
            />

            <PublicRoute
              exact
              path={routesObj.RequestAssistance.path}
              // render={() => routesObj.RequestAssistance.component}
              component={AuthHOC(routesObj.RequestAssistance)}
            />

            {/* ****************** Head Teacher Routes */}

            <PublicRoute
              exact
              path={routesObj.ForgotPassword.path}
              render={() => routesObj.ForgotPassword.component}
            />
            <PublicRoute
              exact
              path={routesObj.ResetPassword.path}
              render={() => routesObj.ResetPassword.component}
            />
            <PublicRoute
              exact
              path={routesObj.ForgotPassword.path}
              render={() => routesObj.ForgotPassword.component}
            />
            <PublicRoute
              exact
              path={routesObj.ResetPassword.path}
              render={() => routesObj.ResetPassword.component}
            />
            <PublicRoute
              exact
              path={routesObj.Account.path}
              render={() => routesObj.Account.component}
            />

            <PublicRoute
              exact
              path={routesObj.StaffResults.path}
              render={() => routesObj.StaffResults.component}
            />

            <PublicRoute
              exact
              path={routesObj.StaffManagement.path}
              //render={() => routesObj.StaffManagement.component}
              component={AuthHOC(routesObj.StaffManagement)}
            />
            {/* <PublicRoute
              exact
              path={routesObj.MatAccount.path}
              component={AuthHOC(routesObj.MatAccount)}
            /> */}

            <PublicRoute
              exact
              path={routesObj.RegisterDirector.path}
              render={() => routesObj.RegisterDirector.component}
            />
            <PublicRoute
              exact
              path={routesObj.MatSchools.path}
              component={AuthHOC(routesObj.MatSchools)}
            />

            <PublicRoute
              exact
              path={routesObj.MatStaffManagement.path}
              component={AuthHOC(routesObj.MatStaffManagement)}
            />

            <PublicRoute
              exact
              path={routesObj.StudentList.path}
              render={() => routesObj.StudentList.component}
            />
            <PublicRoute
              exact
              path={routesObj.StudentSyncSetting.path}
              render={() => routesObj.StudentSyncSetting.component}
            />
            <PublicRoute
              exact
              path={routesObj.AccessManagement.path}
              render={() => routesObj.AccessManagement.component}
            />

            <PublicRoute
              exact
              path={routesObj.FilterLibrary.path}
              render={() => routesObj.FilterLibrary.component}
            />
            <PublicRoute exact render={() => routesObj.NotFound.component} />
          </Switch>
        </Router>
      </MainWrapper>
    );
  }
}
const PublicRoute = (props) => {
  return (
    <PageWrapper>
      <BaseCSS css={customCSS} />
      <Route {...props} />
    </PageWrapper>
  );
};

export default Routes;
