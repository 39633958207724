import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Sidebar from 'react-sidebar';
import { bindActionCreators } from 'redux';
import RequestAssistanceButton from '../../components/common/RequestAssistanceButton';
import {
  iconAnalytics,
  iconDashboard,
  iconHeatmap,
  iconPresentation,
  iconUserGuide,
  welbeeDashboardLogo,
  iconStaffManagement,
  iconQuestionBank,
  welbeeLogo,
  iconReport,
  iconAccount,
} from '../../resources/images/index';
import {
  matGroupService,
  storageService,
  accountService,
} from '../../services';
import { MenuItems } from '../../utils';
import { navigationActions } from '../../store/headerStore';
import { routesObj } from '../../routes';
import { getQueryStringVal } from '../../utils/CommonFunctions';
import { QueryStringTokens } from '../../utils';
import { UserGuideSurveyLink, WebStorageNames } from '../../utils/Constants';
import IdleTimerContainer from './IdleTimerContainer';

const styles = {
  contentHeaderMenuLink: {
    textDecoration: 'none',
    color: '#ffffff',
    background: '#FF5939',
    width: 38,
    height: 38,
    display: 'inline-block',
    textAlign: 'center',
    lineHeight: '53px',
    borderRadius: '8px',
  },
};

const mql = window.matchMedia('(min-width: 1000px)');

class DirectorLeftMenu extends Component {
  constructor(props) {
    super();
    this.state = {
      docked: mql.matches,
      open: false,
      logoUrl: '',
      region: storageService.getItem(WebStorageNames.UsRegion),
      groupType: 'Group Report',
    };
    this.onClickHandler = this.onClickHandler.bind(this);
    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
    this.onSetOpen = this.onSetOpen.bind(this);
  }

  componentWillMount() {
    this.getRegions();
    mql.addListener(this.mediaQueryChanged);
    let token = getQueryStringVal(QueryStringTokens.token);
    let matId = storageService.getItem(WebStorageNames.MatGroupId);
    if (token == null) token = null;
    matGroupService.getMatGroupLogo(token, matId).then((response) => {
      if (response.data != null && response.data != undefined)
        this.setState({ logoUrl: response.data });
    });

    let groupType = storageService.getItem('groupType');
    if (groupType) {
      this.setState({
        groupType: `${groupType} Report`,
      });
    } else {
      matGroupService.getMatById(matId).then((response) => {
        if (response?.data?.GroupName) {
          this.setState({
            groupType: `${response.data.GroupName} Report`,
          });

          storageService.setItem('groupType', response.data.GroupName);
        }
      });
    }
  }

  getRegions = () => {
    accountService
      .checkCurrentRegion()
      .then((response) => {
        storageService.setItem(WebStorageNames.UsRegion, response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  componentWillUnmount() {
    mql.removeListener(this.mediaQueryChanged);
  }

  onSetOpen(open) {
    this.setState({ open });
  }

  mediaQueryChanged() {
    this.setState({
      docked: mql.matches,
      open: false,
    });
  }

  toggleOpen(ev) {
    this.setState({ open: !this.state.open });

    if (ev) {
      ev.preventDefault();
    }
  }

  getLogoutMenu() {
    return (
      <li className='btn-logout'>
        <a
          href
          onClick={() => {
            storageService.clear();
            this.props.history.push(routesObj.Login.path);
          }}
        >
          Logout
        </a>
      </li>
    );
  }

  redirectToRouteWithMatGroupId(routeName) {
    let matGroupId = storageService.getItem(WebStorageNames.MatGroupId);
    this.props.history.push(routeName + '?matGroupId=' + matGroupId);
  }
  redirectToRoute(routeName) {
    let token = getQueryStringVal(QueryStringTokens.token);
    if (token !== '' && token !== null && token !== 'null') {
      this.props.history.push(routeName + '?token=' + token);
    } else this.props.history.push(routeName);
  }

  onClickHandler = (name) => {
    this.props.actions.activeItemAction({
      item: name,
      showReportSubMenu: false,
    });
  };

  render() {
    const contentHeader = (
      <div className='main-header-content'>
        <div>
          {!this.state.docked && (
            <a
              onClick={this.toggleOpen}
              href='#'
              style={styles.contentHeaderMenuLink}
            >
              <MenuIcon />
            </a>
          )}
        </div>
        <div className='mobile-logo'>
          <img
            src={
              this.state.logoUrl !== null
                ? this.state.logoUrl
                : welbeeDashboardLogo
            }
            alt=''
          />
        </div>
        <Box
          sx={{
            display: {
              xs: 'block',
              sm: 'block',
              md: 'block',
              xl: 'none',
              lg: 'none',
            },
          }}
        >
          <div className='btn-request-assistance'>
            <RequestAssistanceButton />
          </div>
        </Box>
      </div>
    );

    const { activeItem: navActiveMenu } = this.props;
    return (
      <>
        <IdleTimerContainer />
        <Sidebar
          className='leftsidebar'
          sidebar={
            <Scrollbars
              className='scrollbar-view'
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
            >
              <div className='leftsidemenu'>
                <ul data-id-ul='reportLeftMenus'>
                  <li align='center' style={{ marginBottom: '30px' }}>
                    <img
                      src={
                        this.state.logoUrl !== null
                          ? this.state.logoUrl
                          : welbeeDashboardLogo
                      }
                      alt=''
                      className='school-logo'
                    />
                  </li>

                  <li className='voice-nav'>
                    <a
                      href='javascript:void(0);'
                      onClick={() => {
                        this.onClickHandler(MenuItems.MatStaffManagement);
                        this.redirectToRoute(routesObj.MatStaffManagement.path);
                      }}
                      className={
                        navActiveMenu === MenuItems.MatStaffManagement
                          ? 'active'
                          : ''
                      }
                    >
                      <img src={iconStaffManagement} alt='' /> Staff Management
                    </a>
                  </li>

                  <li className='voice-nav'>
                    <a
                      href='javascript:void(0);'
                      onClick={() => {
                        this.onClickHandler(MenuItems.MatAccount);
                        this.redirectToRoute(routesObj.MatAccount.path);
                      }}
                      className={
                        navActiveMenu === MenuItems.MatAccount ? 'active' : ''
                      }
                    >
                      <img src={iconAccount} alt='' /> Account
                    </a>
                  </li>

                  <li className='voice-nav'>
                    <a
                      href='javascript:void(0);'
                      onClick={() => {
                        this.onClickHandler(MenuItems.UserGuide);
                        var win = window.open(UserGuideSurveyLink, '_blank');
                        win.focus();
                      }}
                      className={
                        navActiveMenu === MenuItems.UserGuide ? 'active' : ''
                      }
                    >
                      <img src={iconUserGuide} alt='' /> <span>User Guid</span>e
                    </a>
                  </li>

                  <div className='btn-bottom'>{this.getLogoutMenu()}</div>
                </ul>
              </div>
            </Scrollbars>
          }
          open={this.state.open}
          docked={this.state.docked}
          onSetOpen={this.onSetOpen}
        >
          <div className='main-header'>{contentHeader}</div>
        </Sidebar>
      </>
    );
  }
}
const mapStateToProps = ({
  header: {
    activeItem,
    showReportSubMenu,
    disableReportMenu,
    newCommentCount,
    toggleButton,
    newConversationCount,
    logoURL,
  },
  user: { freeTrailEndDate },
}) => ({
  activeItem,
  showReportSubMenu,
  disableReportMenu,
  newCommentCount,
  toggleButton,
  newConversationCount,
  logoURL,
  freeTrailEndDate,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(navigationActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DirectorLeftMenu));
