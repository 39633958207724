import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loaderAction } from '../../store/loaderStore';
import { navigationActions } from '../../store/headerStore';
import { storageService } from '../../services';
import {
  WebStorageNames,
  QueryStringTokens,
  MenuItems,
  AccessPermissions,
  Roles,
} from '../../utils/Constants';
import { dashboardService } from '../../services';
import { getQueryStringVal } from '../../utils/CommonFunctions';
import { firstLoginAction } from '../../store/userStore';
import { AssistanceWrapper } from '../../resources/styling/appStyle';
import RequestAssistanceButton from '../../components/common/RequestAssistanceButton';
import { setTextLengthLimit } from '../../utils/CommonFunctions';
import { schoolService, schoolSubscriptionService } from '../../services';
import { iconAnalytics, welbeeLogoCircle } from '../../resources/images/index';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import AccessLevel from './AccessLevel';
import { Button, Typography } from '@mui/material';
import { routesObj } from '../../routes';

class TopHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schoolName: '',
      strongestScore: [{}],
      weakestScore: [{}],
      activeSurveyDetails: '',
      comments: [],
      commentsLoader: false,
      IsMultiSurvey: false,
      welbeeType: false,
      isFirstLogin: false,
      accessLevel: 0,
      highestScore: 0,
      SchoolRecord: null,
      schoolId: null,
      isStaff: false,
      schoolSubscription: '',
      UserFirstName: '',
      UserLastName: '',
      UserEmail: '',
      stickyheader: false,
    };
  }

  componentDidMount() {
    const schoolToken = getQueryStringVal(QueryStringTokens.schoolToken);
    const schoolId = storageService.getItem(WebStorageNames.SchoolId);
    schoolSubscriptionService
      .getSchoolSubscriptionsById(schoolId)
      .then((response) => {
        this.setState({
          schoolSubscription: response.data.SubscriptionId,
        });
      })
      .catch((error) => console.log(error));

    schoolService
      .getSchoolDetail(schoolId, schoolToken)
      .then((response) => {
        storageService.setItem(
          WebStorageNames.HeadTeacherId,
          response.data.headTeacher.Id
        );
        this.setState({
          SchoolRecord: response.data,
          schoolName: response.data.school.Name,
          UserFirstName: response.data.headTeacher.FirstName,
          UserLastName: response.data.headTeacher.LastName,
          UserEmail: response.data.school.Email,
          welbeeAccountType: response.data.school.WelbeeVoiceAccountType,
        });
      })
      .catch((error) => console.log(error));
  }
  accountMenu = () => {
    const [anchorMenu, setAnchorMenu] = React.useState(null);
    const open = Boolean(anchorMenu);
    const handleClick = (event) => {
      setAnchorMenu(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorMenu(null);
    };
    return (
      <>
        <Box
          sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}
        >
          <Tooltip title='Account settings'>
            <IconButton
              onClick={handleClick}
              size='small'
              sx={{ ml: 2 }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
            >
              <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
            </IconButton>
          </Tooltip>
        </Box>
        <Menu
          anchorMenu={anchorMenu}
          id='account-menu'
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem>
            <ListItemIcon>
              <Settings fontSize='small' />
            </ListItemIcon>
            Settings
          </MenuItem>
          <MenuItem>
            <ListItemIcon>
              <Logout fontSize='small' />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </>
    );
  };

  ChangeBackground = () => {
    if (window.scrollY >= 10) {
      this.setState({ stickyheader: true });
    } else {
      this.setState({ stickyheader: false });
    }
  };
  UsRegion = () => {
    let usRegion = storageService.getItem(WebStorageNames.UsRegion);
    return usRegion;
  };
  render() {
    const {
      schoolName,
      UserFirstName,
      UserLastName,
      UserEmail,
      schoolSubscription,
      welbeeAccountType,
      stickyheader,
    } = this.state;
    window.addEventListener('scroll', this.ChangeBackground);

    return (
      <AssistanceWrapper>
        <Grid container>
          <Grid item xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
            <AppBar
              className={stickyheader ? 'stickyheader sticky' : 'stickyheader'}
            >
              <div className='main-app-header'>
                <div className='dashboard-school-name'>
                  <h1>{setTextLengthLimit(schoolName)}</h1>
                  {/* {this.UsRegion() !== 'us' && (
                    <div className='subscription'>
                      {schoolSubscription === 1
                        ? 'Voice Free'
                        : schoolSubscription === 2
                        ? 'Voice Pro'
                        : schoolSubscription === 3
                        ? 'Survey Free'
                        : schoolSubscription === 4
                        ? 'Survey Pro'
                        : schoolSubscription === 5
                        ? 'Survey Premium'
                        : 'Free'}
                    </div>
                  )} */}
                  <span className='subtitle'>Staff Wellbeing Overview</span>
                </div>

                {/* test start */}
                <Toolbar>
                  {/* App Name or Logo */}
                  <Typography variant='h6' sx={{ flexGrow: 1 }}>
                    My App
                  </Typography>

                  {/* Menu Items in Header */}
                  <Button>Home</Button>
                  <Button color='inherit'>About</Button>
                  <Button color='inherit'>Contact</Button>

                  {/* Dropdown Menu */}
                  <IconButton
                    color='inherit'
                    //  onClick={handleMenuOpen}
                    aria-controls='settings-menu'
                    aria-haspopup='true'
                  >
                    {/* <SettingsIcon /> */}
                  </IconButton>
                </Toolbar>
                {/* test end */}
                <AccessLevel
                  permissions={[
                    AccessPermissions.Full,
                    AccessPermissions.HeadTeacher,
                    AccessPermissions.View,
                  ]}
                >
                  <Button
                    // color={navActiveMenu === MenuItems.Analytics ? 'secondary' : 'inherit'}
                    onClick={() => {
                      this.onClickHandler({
                        name: MenuItems.Analytics,
                        showReportSubMenu: false,
                      });
                      let schoolToken = getQueryStringVal(
                        QueryStringTokens.schoolToken
                      );
                      if (
                        this.state.role === Roles.GroupDirector ||
                        schoolToken != null
                      ) {
                        this.redirectToRouteWithSchoolToken(
                          routesObj.StudentSyncSetting.path
                        );
                      } else {
                        this.redirectToRoute(routesObj.StudentSyncSetting.path);
                      }
                      //  amplitudeLog(eventName, 'SideMenu' + eventName, '');
                    }}
                    startIcon={<img src={iconAnalytics} alt='Analytics Icon' />}
                  >
                    Sync Setting
                  </Button>
                </AccessLevel>

                <div className='main-app-user'>
                  <ul data-id-ul='reportLeftMenus'>
                    <li align='center'>
                      <img
                        src={
                          this.state.schoolLogo !== null
                            ? this.state.schoolLogo
                            : { welbeeLogoCircle }
                        }
                        alt=''
                        className='school-logo'
                      />
                    </li>{' '}
                  </ul>
                  <div className='user-account'>
                    <Avatar
                      sx={{
                        bgcolor: '#fff',
                        float: 'left',
                        marginRight: '8px',
                        width: 45,
                        height: 45,
                        borderRadius: '8px',
                      }}
                      variant='rounded'
                    >
                      <img src={welbeeLogoCircle} alt='' />
                    </Avatar>
                    <Box sx={{ float: 'left' }}>
                      <span className='username'>
                        Welcome{' '}
                        {setTextLengthLimit(UserFirstName + ' ' + UserLastName)}
                      </span>
                      <span className='useremail'>
                        {setTextLengthLimit(UserEmail)}
                      </span>
                    </Box>
                  </div>
                  <div className='btn-request-assistance'>
                    <RequestAssistanceButton />
                  </div>
                </div>
              </div>
            </AppBar>
          </Grid>
        </Grid>
      </AssistanceWrapper>
    );
  }
}
const mapStateToProps = ({ header: { newCommentCount } }) => {
  return {
    newCommentCount,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(dashboardService, dispatch),
  loaderActions: bindActionCreators(loaderAction, dispatch),
  navigationActions: bindActionCreators(navigationActions, dispatch),
  userAction: bindActionCreators(firstLoginAction, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TopHeader));
