import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import { AppButton } from '../../resources/styling';
import { colors } from '../../resources/theme/colors';
import {
  matGroupService,
  storageService,
  accountService,
} from '../../services';
import { Dropdown } from '../../components';
import { searchIconWhite } from '../../resources/images';
import {
  CheckBox,
  SchoolListMainWrapper,
  TableStyle,
} from '../../resources/styling/appStyle';
import { routesObj } from '../../routes';
import { WebStorageNames } from '../../utils';
import { DateFormat } from '../../utils/CommonFunctions';
import Grid from '@mui/material/Grid';
import ConfirmPopupForm from '../../components/common/ConfirmPopupForm';
import {
  SchoolSurveyWrapper,
  SchoolSurveyInnerMain,
  SchoolSurveyInner,
} from './MatStyle';
import IdleTimerContainer from '../../components/common/IdleTimerContainer';
import PulseButton from '../../components/PulseButton';

const { mandy, teal } = colors;
const SortOrder = {
  ASC: 'Asc',
  DESC: 'Desc',
  NewestFirst: 'Newest',
};

const nameFilterOptions = [
  { label: SortOrder.ASC, value: 'Asc' },
  { label: SortOrder.DESC, value: 'Desc' },
  { label: SortOrder.NewestFirst, value: 'Newest' },
];

function MatAccounts(props) {
  const [matGroups, setMatGroups] = useState([]);
  const [nameFilterSelectedOption, setNameFilterSelectedOption] = useState({
    label: SortOrder.ASC,
    value: 'Asc',
  });
  const [allFilterMatData, setAllFilterMatData] = useState([]);
  const [isArchive, setIsArchive] = useState(false);
  const [groupToDelete, setGroupToDelete] = useState();
  const [selectTestAccount, setSelectTestAccount] = useState(false);

  useEffect(() => {
    getAllMatGroups();
    setAllFilterMatData(matGroups);
  }, {});
  useEffect(() => {
    console.log(allFilterMatData);
  }, [allFilterMatData]);

  const deleteHandler = () => {
    matGroupService
      .archiveGroup(groupToDelete.Id)
      .then((response) => {
        setIsArchive(false);
        toast.success('Record deleted sucessfully');
        getAllMatGroups();
      })
      .catch((error) =>
        toast.error('Record not deleted. Please try again later.')
      );
  };

  const handleNameSortFilterChange = (event) => {
    let value = event;
    setNameFilterSelectedOption(value);
    let result = [];
    if (value.label === SortOrder.ASC) {
      result = matGroups.sort((a, b) => {
        if (a.Name.toLocaleLowerCase() < b.Name.toLocaleLowerCase()) return -1;
        if (a.Name.toLocaleLowerCase() > b.Name.toLocaleLowerCase()) return 1;
        return 0;
      });
    } else if (value.label === SortOrder.DESC) {
      result = matGroups.sort((a, b) => {
        if (b.Name.toLocaleLowerCase() < a.Name.toLocaleLowerCase()) return -1;
        if (b.Name.toLocaleLowerCase() > a.Name.toLocaleLowerCase()) return 1;
        return 0;
      });
    } else if (value.label === SortOrder.NewestFirst) {
      result = matGroups.sort((a, b) => {
        const a_date = new Date(a.AddedDate);
        const b_date = new Date(b.AddedDate);
        if (b_date < a_date) return -1;
        if (b_date > a_date) return 1;
        return 0;
      });
    } else {
      result = matGroups.sort((a, b) => {
        if (a.Name.toLocaleLowerCase() < b.Name.toLocaleLowerCase()) return -1;
        if (a.Name.toLocaleLowerCase() > b.Name.toLocaleLowerCase()) return 1;
        return 0;
      });
    }
    setAllFilterMatData(result);
  };
  const handleNameSearchChange = (event) => {
    let value = event.target.value.toLowerCase();
    let result = [];
    matGroups.sort((a, b) => {
      if (a.Name.toLocaleLowerCase() < b.Name.toLocaleLowerCase()) return -1;
      if (a.Name.toLocaleLowerCase() > b.Name.toLocaleLowerCase()) return 1;
      return 0;
    });
    result = matGroups.filter((data) =>
      data.Name.toLocaleLowerCase().includes(value.toLocaleLowerCase())
    );
    setAllFilterMatData(result);
  };

  function getAllMatGroups() {
    matGroupService.getAllMatGroups().then((response) => {
      let filteredGroupsList = response.data;
      filteredGroupsList = filteredGroupsList.filter(
        (item) => !item.IsArchived
      );

      setMatGroups(filteredGroupsList);
      byDefaultAscOrder(filteredGroupsList);
    });
  }

  function byDefaultAscOrder(data) {
    let result = [];
    result = data.sort((a, b) => {
      if (a.Name.toLocaleLowerCase() < b.Name.toLocaleLowerCase()) return -1;
      if (a.Name.toLocaleLowerCase() > b.Name.toLocaleLowerCase()) return 1;
      return 0;
    });
    setAllFilterMatData(result);
  }

  const selectTestAccountCheckboxChangeHandler = (event) => {
    setSelectTestAccount(event.target.checked);
    let result = [];

    result = matGroups.filter((x) => x.IsTestAccount !== selectTestAccount);
    setAllFilterMatData(result);
  };

  function redirectToSchools(item) {
    storageService.setItem(WebStorageNames.MatGroupId, item.Id);
    props.history.push(routesObj.MatSchools.path);
  }

  function redirectToEditMat(item) {
    getRegions();
    storageService.setItem(WebStorageNames.MatGroupId, item.Id);
    const { GroupDirectors } = item;
  }

  const getRegions = () => {
    accountService
      .checkCurrentRegion()
      .then((response) => {
        storageService.setItem(WebStorageNames.UsRegion, response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function redirectToGroupDashboard(item) {
    const { GroupDirectors } = item;

    storageService.setItem(WebStorageNames.MatGroupId, item.Id);
    storageService.setItem(
      WebStorageNames.IsDisplayMatHeatMap,
      item.IsDisplayMatHeatMap
    );
  }
  const redirectToMatCycle = (item) => {
    storageService.setItem(WebStorageNames.MatGroupId, item.Id);
    props.history.push(routesObj.MatCycles.path);
  };
  return (
    <div>
      <SchoolSurveyWrapper>
        <SchoolSurveyInnerMain>
          <SchoolSurveyInner>
            <SchoolListMainWrapper>
              <Grid container>
                <Grid item xl={2} lg={2} md={12} sm={12}>
                  <h1 style={{ 'max-height': '60px', margin: '0px' }}>
                    Groups Account List
                  </h1>
                </Grid>

                <Grid item xs={12} xl={2} lg={2} md={12} sm={12}>
                  <h1 style={{ 'max-height': '60px', margin: '0px' }}>
                    <div className='test-checkbox'>
                      <CheckBox>
                        <input
                          className='styled-checkbox'
                          type='checkbox'
                          id='selectTestAccountCheckbox'
                          value='value1'
                          checked={selectTestAccount}
                          onChange={(e) =>
                            selectTestAccountCheckboxChangeHandler(e)
                          }
                        />
                        <label for='selectTestAccountCheckbox'>
                          <span
                            className='checkbox-wrapper'
                            style={{ color: 'white' }}
                          >
                            Test Accounts
                          </span>
                        </label>
                      </CheckBox>
                    </div>
                  </h1>
                </Grid>

                <Grid
                  item
                  xs={12}
                  xl={8}
                  lg={8}
                  md={12}
                  sm={12}
                  className='px-0'
                >
                  <h1
                    style={{ 'min-height': '60px' }}
                    className='super-admin-header'
                  >
                    <div className='comments-search-filters'>
                      <div className='subcontainer-inner school-list-header'>
                        <div className='search-filters'>
                          <Dropdown
                            name='NameSortFilter'
                            options={nameFilterOptions}
                            selectedOption={nameFilterSelectedOption}
                            value={nameFilterSelectedOption}
                            onChange={(value) =>
                              handleNameSortFilterChange(value)
                            }
                          />
                        </div>
                        <div className='search-filed'>
                          <img src={searchIconWhite} alt='search button' />
                          <input
                            id='searchInputField'
                            placeholder='Enter a keyword'
                            icon='search'
                            className='searchField'
                            onChange={(event) => {
                              handleNameSearchChange(event);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </h1>
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12}>
                  <TableStyle>
                    <table>
                      <thead>
                        <tr>
                          <th className='list-heading'>Name</th>
                          <th className='list-heading'>Account Holder Name</th>
                          <th className='list-heading'>Email</th>
                          <th className='list-heading'>Registration Date</th>
                          <th className='list-heading list-action'>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allFilterMatData.map((item, i) => (
                          <tr key={item.Id}>
                            <td>{item.Name}</td>
                            <td>
                              {item.FirstName} {item.LastName}
                            </td>
                            <td>
                              {item.Email == null ? 'Not Registed' : item.Email}
                            </td>
                            <td>{DateFormat(item.AddedDate)}</td>
                            <td>
                              <AppButton
                                color={teal}
                                type='submit'
                                onClick={() => redirectToGroupDashboard(item)}
                              >
                                View
                              </AppButton>
                              <AppButton
                                color={teal}
                                type='submit'
                                onClick={() => redirectToMatCycle(item)}
                              >
                                Cycle
                              </AppButton>
                              <AppButton
                                color={teal}
                                type='submit'
                                onClick={() => redirectToSchools(item)}
                              >
                                Tag School
                              </AppButton>
                              <AppButton
                                color={mandy}
                                type='submit'
                                onClick={() => redirectToEditMat(item)}
                              >
                                Edit
                              </AppButton>
                              <AppButton
                                color={mandy}
                                type='submit'
                                onClick={() => {
                                  setIsArchive(true);
                                  setGroupToDelete(item);
                                }}
                              >
                                Delete
                              </AppButton>

                              <PulseButton
                                id={item.Id}
                                name={item.Name}
                                email={item.Email}
                                ismat={true}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </TableStyle>
                </Grid>
              </Grid>
            </SchoolListMainWrapper>

            <ConfirmPopupForm
              isOpen={isArchive}
              onClose={() => setIsArchive(false)}
              onOk={deleteHandler}
              successPopupMessage='Are you sure to archive this group?'
            />
          </SchoolSurveyInner>
        </SchoolSurveyInnerMain>
      </SchoolSurveyWrapper>
    </div>
  );
}
const mapStateToProps = ({ superAdmin: { SetSchoolsListFlag } }) => ({
  SetSchoolsListFlag,
});

export default connect(mapStateToProps, null)(withRouter(MatAccounts));
