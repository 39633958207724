import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { includes } from 'lodash';
import { routesObj } from '../../routes';
import { parseJwt } from '../../utils/CommonFunctions';
import { WebStorageNames, Roles } from '../../utils/Constants';
import { storageService } from '../../services';
import { WelbeeType, HeadTeacherAccess } from '../../utils';

const AuthHOC = (componentObj) => {
  const viewRoleNotAllowedRoutes = [
    //routesObj.ActionPlan.path,
    //routesObj.StaffManagement.path,
    routesObj.RequestAssistance.path,
  ];
  const adminRoleAllowedRoutes = [
    routesObj.Account.path,
    routesObj.StaffManagement.path,
  ];
  class WithAuth extends Component {
    render() {
      const token = storageService.getItem(WebStorageNames.Token);
      const welbeeType = storageService.getItem(WebStorageNames.WelbeeType);
      const accessLevel = storageService.getItem(WebStorageNames.AccessLevel);
      if (welbeeType === WelbeeType.WelbeeVoice) {
        if (accessLevel === HeadTeacherAccess.Full) {
          return componentObj.component;
        } else if (
          accessLevel === HeadTeacherAccess.Admin &&
          adminRoleAllowedRoutes.indexOf(componentObj.path) >= 0
        ) {
          return componentObj.component;
        } else if (
          accessLevel === HeadTeacherAccess.View &&
          (componentObj.path === routesObj.WelbeeVoice?.path ||
            componentObj.path === routesObj.StaffManagement?.path ||
            componentObj.path === routesObj.Account?.path)
        ) {
          return componentObj.component;
        } else {
          return <Redirect to={routesObj.Login.path} />;
        }
      } else if (
        welbeeType === WelbeeType.WelbeeSurveyWithVoice ||
        welbeeType === WelbeeType.Group
      ) {
        if (accessLevel === HeadTeacherAccess.Full) {
          return componentObj.component;
        } else if (
          accessLevel === HeadTeacherAccess.Admin &&
          adminRoleAllowedRoutes.indexOf(componentObj.path) >= 0
        ) {
          return componentObj.component;
        } else if (
          accessLevel === HeadTeacherAccess.View &&
          viewRoleNotAllowedRoutes.indexOf(componentObj.path) < 0
        ) {
          return componentObj.component;
        } else {
          return <Redirect to={routesObj.Login.path} />;
        }
      } else {
        if (!token) {
          return <Redirect to={routesObj.Login.path} />;
        }
        const userInfo = parseJwt(token);
        if (
          userInfo.role === Roles.SuperAdmin ||
          userInfo.role === Roles.HeadTeacher ||
          userInfo.role === Roles.GroupDirector
        ) {
          if (includes(componentObj.roles, userInfo.role)) {
            return componentObj.component;
          }
        }
        return <Redirect to={componentObj.fallback} />;
      }

      /*if (welbeeType === WelbeeType.WelbeeVoice) {
        if (
          componentObj.path === routesObj.WelbeeVoice.path 
        ) {
          return componentObj.component;
        } else {
          return <Redirect to={routesObj.Login.path} />;
        }
      } else {
        if (!token) {
          return <Redirect to={routesObj.Login.path} />;
        }
        const userInfo = parseJwt(token);

        if (
          userInfo.role === Roles.SuperAdmin ||
          userInfo.role === Roles.HeadTeacher
        ) {
          if (includes(componentObj.roles, userInfo.role)) {
            return componentObj.component;
          }
        }
        return <Redirect to={componentObj.fallback} />;
      }*/
    }
  }

  return connect(null, null)(WithAuth);
};

export default AuthHOC;
